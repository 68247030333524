const initialState = {
    detaildokter: null,
    status: false,
    isactive: false,
    error_message: null,
    id: null,
}

export default (state = initialState, action) => {
    switch(action.type){
        case 'GET_DETIL_DOKTER':
            return {
                ...state,
                detaildokter: action.payload.detaildokter,
                isactive: action.payload.isactive
            }
        case 'POST_ACTIVATE_ACCOUNT':
            return {
                ...state,
                status: action.payload.status,
                id: action.payload.id
            }
        case 'POST_ACTIVATE_ACCOUNT_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        default : 
            return state;
    }
}