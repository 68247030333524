import React from 'react';
import PropTypes from 'prop-types';

const Switch = props => {
    Switch.propTypes = {
        isChecked: PropTypes.bool.isRequired
    };
    return (
    	<div className="flex mt-2">
            <label>
                <input checked={ props.isChecked } onChange={props.onChange} className="switch" type="checkbox" />
                <div>
                    <div></div>
                </div>
            </label>
        </div>
    );
}
export default Switch;