import React,{ useEffect,useState } from 'react';
import Notif from '../../../../../components/atoms/notif';
import TitlePage from '../../../../../components/molecules/TitlePage';
import Tabs from '../../../../../components/molecules/Tabs'
import * as DetailDokterComponents from '../../../../../components/molecules/Dokter'
// import log from '../../../../../utils/log';
import { useHistory } from 'react-router-dom';
import Modal from '../../../../../components/molecules/Modal'
import  * as DokterActions from '../../../../../config/redux/action/dokter'
import { useStore, useDispatch, useSelector } from 'react-redux'
import SelectSearch from 'react-select-search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const DokterDetail = props => {
    
    // const [detailDokter, setDetailDokter] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [titleModal, setTitleModal] = useState("")
    const [bodyModal, setBodyModal] = useState("")
    const [btnCancelText, setBtnCancelText] = useState("Batal")
    const [btnOkText, setBtnOkText] = useState("Ok")
    const [modalColor, setModalColor] = useState("green")
    const [modalOnSubmit, setModalOnSubmit] = useState(() => () => console.log(''))
    const [optionFaskes, setOptionFaskes] = useState([])
    const [selectedFaskes, setSelectedFaskes] = useState("")
    const [selectedTipe, setSelectedTipe] = useState("")
    const [menit, setMenit] = useState(1)
    const [harga, setHarga] = useState(0)
    const [notify,setNotify] 	= useState({show:'hidden'});
    const dispatch = useDispatch()
    const [filterData,setFilterData] = useState({
        sortdatafield   : null,
        sortorder       : null,
        pagenum         : 0,
        pagesize        : 10,
        search_category : 'all',
        search_value    : null,
    });

    const history = useHistory()

    const detailDokter = useSelector((state) => state.dokter.detaildokter)
    const berkasDokter = useSelector((state) => state.dokter.berkas)
    const faskesDokter = useSelector((state) => state.dokter.dokterfaskes)
    const faskesDokterTotal = useSelector((state) => state.dokter.dokterfaskestotal)
    const tarifDokter = useSelector((state) => state.dokter.doktertarif)
    const tarifDokterTotal = useSelector((state) => state.dokter.doktertariftotal)
    const faskesList = useSelector((state) => state.dokter.listfaskes)
    
    const id = props.location.state.id
    const store = useStore()
    const loadDetail = () => {
        dispatch(DokterActions.getDetil(id))
        dispatch(DokterActions.getFaskesDokter(id, filterData))
        dispatch(DokterActions.getTarifDokter(id, filterData))
    }

    const tarifkategori = [{value: 'chat', name: "Percakapan"},{value: 'vicall', name: "Video Call"}]

    useEffect(() => {
        loadDetail()
    },[filterData])

    const handleRefresh = () => {
        dispatch(DokterActions.getFaskesDokter(id, filterData))
    }

    const handleRefreshTarif = () => {
        dispatch(DokterActions.getTarifDokter(id, filterData))
    }

    const handleSearchKeyUp = (event) => {
        let value = event.target.value;
        if(value.length > 2){
            setFilterData(prevState => ({
                ...prevState,
                search_value: value,
            }))
            dispatch(DokterActions.getFaskesDokter(id, filterData))
        } else if (value.length === 0){
            setFilterData(prevState => ({
                ...prevState,
                search_value: null,
            }))
            dispatch(DokterActions.getFaskesDokter(id, filterData))
        }
    }

    const handleSelectChange = (event) => {
        let value = event.target.value;
        setFilterData(prevState => ({
            ...prevState,
            search_category    : value,
        }))
    }

    const handleSelectPageNum = (event) => {
        let value = event.target.value;
        setFilterData(prevState => ({
            ...prevState,
            pagesize    : value,
        }))
    }

    const handleMovePage = (type,id) => {
        if(type === 'prev'){
            if(filterData.pagenum === 0){
                setNotify(	{
                    type:'danger',show:'block',title:'Data Faskes',message:'Sudah dihalaman satu'
                });
                setTimeout(() => {
                    setNotify({
                        show: 'hidden'
                    });
                }, 1500);
            }else{
                setFilterData(prevState => ({
                    ...prevState,
                    pagenum    : prevState.pagenum-1,
                }))
            }
        }else{
            if(((parseInt(filterData.pagenum+1))*filterData.pagesize) <= faskesDokterTotal){
                setFilterData(prevState => ({
                    ...prevState,
                    pagenum    : prevState.pagenum+1,
                }))
                
            }else{
                setNotify(	{
                    type:'danger',show:'block',title:'Data Faskes',message:'Halaman sudah habis'
                });
                setTimeout(() => {
                    setNotify({
                        show: 'hidden'
                    });
                }, 1500);
            }
            
        }
    }

    const handleSelectSearch = async (query) => {
        await dispatch(DokterActions.getListFaskes(query))
        setOptionFaskes(faskesList)
    }

    const handleAddFaskes = () => {
        setTitleModal('Tambah Faskes')
        setBodyModal("Apakah Anda yakin akan menambahkan faskes ini?")
        setBtnCancelText("Batal")
        setBtnOkText("Ya, Tambahkan")
        setModalColor("green")
        setShowModal(!showModal)
        setModalOnSubmit(() => modalAddFaskes)
    }

    const modalAddFaskes = async () => {
        await dispatch(DokterActions.postAddFaskes(id,selectedFaskes))
        
        const error_message = store.getState().dokter.error_message
        const status_add_faskes = store.getState().dokter.status_add_faskes
        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Dokter',message:error_message
            }
            setNotify(notif)
            setShowModal(false)

            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }

        console.log('status: ', status_add_faskes)
        if (status_add_faskes) {
            const message = ["Data berhasil disimpan"]
            const notif = {
                type:'success',show:'block',title:'Dokter',message:message
            }
            handleRefresh()
            setSelectedFaskes("")
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }
    }

    const onDeleteFaskes = async (id,code) => {
        await dispatch(DokterActions.postDeleteFaskes(id,code))
        
        const error_message = store.getState().dokter.error_message
        const status_delete_faskes = store.getState().dokter.status_delete_faskes
        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Dokter',message:error_message
            }
            setNotify(notif)
            setShowModal(false)

            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }

        console.log('status: ', status_delete_faskes)
        if (status_delete_faskes) {
            const message = ["Data berhasil dihapus"]
            const notif = {
                type:'success',show:'block',title:'Dokter',message:message
            }
            handleRefresh()
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }
    }

    const handleDelete = (id,code) => {
        console.log(id,code)
        setModalOnSubmit(() => () => onDeleteFaskes(id,code))
        setTitleModal('Hapus Faskes')
        setBodyModal("Apakah Anda yakin akan menghapus faskes ini?")
        setBtnCancelText("Batal")
        setBtnOkText("Ya, Hapus")
        setModalColor('red')
        setShowModal(!showModal)
        
    }

    const onDeletePaket = async (id) => {
        await dispatch(DokterActions.postDeleteTarif(id))
        
        const error_message = store.getState().dokter.error_message
        const status_delete_tarif = store.getState().dokter.status_delete_tarif
        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Dokter',message:error_message
            }
            setNotify(notif)
            setShowModal(false)
            handleRefreshTarif()
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }

        console.log('status: ', status_delete_tarif)
        if (status_delete_tarif) {
            const message = ["Data berhasil dihapus"]
            const notif = {
                type:'success',show:'block',title:'Dokter',message:message
            }
            handleRefreshTarif()
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }
    }

    const handleDeletePaket = (id) => {
        console.log(id)
        setModalOnSubmit(() => () => onDeletePaket(id))
        setTitleModal('Hapus Paket')
        setBodyModal("Apakah Anda yakin akan menghapus paket ini?")
        setBtnCancelText("Batal")
        setBtnOkText("Ya, Hapus")
        setModalColor('red')
        setShowModal(!showModal)
        
    }

    const handleAddTarif = () => {
        setTitleModal('Tambah Tarif')
        setBodyModal("Apakah Anda yakin akan menambahkan paket ini?")
        setBtnCancelText("Batal")
        setBtnOkText("Ya, Tambahkan")
        setModalColor("green")
        setShowModal(!showModal)
        setModalOnSubmit(() => modalAddTarif)
    }

    const modalAddTarif = async () => {
        const data = {
            dokter_id: id,
            faskes_code: selectedFaskes,
            tipe: selectedTipe,
            harga: harga,
            menit: menit
        }
        console.log('data tarif: ', data)
        await dispatch(DokterActions.postAddTarif(data))
        
        const error_message = store.getState().dokter.error_message
        const status_add_tarif = store.getState().dokter.status_add_tarif
        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Dokter',message:error_message
            }
            setNotify(notif)
            setShowModal(false)
            handleRefreshTarif()
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }

        console.log('status: ', status_add_tarif)
        if (status_add_tarif) {
            const message = ["Data berhasil disimpan"]
            const notif = {
                type:'success',show:'block',title:'Dokter',message:message
            }
            handleRefreshTarif()
            setSelectedFaskes("")
            setSelectedTipe("")
            setHarga(0)
            setMenit(1)
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }
    }

    return (
    <div className="container mx-auto">
        <Notif 
				notify = {notify}
			/>
        <TitlePage
            title='Master Dokter Detail'
            data = {['Master','Dokter', 'Detail']}
        />

        <Modal show={showModal} title={titleModal} body={bodyModal} onClose={() => {
            setShowModal(!showModal)
        }} btnCancelText={btnCancelText} btnOkText={btnOkText} onSubmit={modalOnSubmit} color={modalColor}/>
        <div className="antialiased font-sans bg-gray">
            <div className="container mx-auto px-4 sm:px-8">
                <div className="py-8">
                    <div className="flex flex-col justify-center object-center align-middle justify-items-center mt-10">
                        <img className="rounded-full border-gray-100 w-48 border-2 self-center" src={detailDokter !== null ? detailDokter.foto : null} alt=""/>
                        <p className="text-9xl mt-2 self-center" style={{ fontSize: 36, fontWeight: 'bold' }}>{detailDokter !== null ? detailDokter.nama_lengkap : null}</p>
                        <p className="text-gray-500 self-center">{detailDokter !== null ? detailDokter.tempat_lahir : null}, {detailDokter !== null ? detailDokter.tanggal_lahir : null}</p>
                    </div>
                    <Tabs> 
                        <div label="Informasi Pribadi">
                            <DetailDokterComponents.Information detailDokter={detailDokter} berkasDokter={berkasDokter} {...props}/>
                        </div> 
                        <div label="Faskes Dokter"> 
                            <div className="antialiased font-sans bg-white">
                                <div className="container mx-auto px-4 sm:px-8">
                                    <div className="py-2">
                                        <h2 className="font-semibold text-lg mt-5">Tambahkan Faskes</h2>
                                        <hr className="mt-2"/>
                                        <div className="flex flex-col mb-4 justify-start">

                                            <div className="flex flex-row mt-4">
                                            <SelectSearch 
                                                options={optionFaskes}
                                                getOptions={handleSelectSearch} 
                                                onChange={(value) => { setSelectedFaskes(value) }}
                                                value={selectedFaskes} 
                                                name="faskes" 
                                                placeholder="Cari nama faskes" 
                                                search 
                                                emptyMessage="Faskes tidak ditemukan"/>
                                                
                                                <span className="sm:ml-3 self-end">
                                                    <button  onClick={handleAddFaskes} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                        <FontAwesomeIcon icon={faPlus} className="mr-2"/>Tambahkan
                                                    </button>
                                                </span>
                                                <span className="sm:ml-3 self-end">
                                                    <button  onClick={() => { history.goBack() }} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>Kembali
                                                    </button>
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DetailDokterComponents.Faskes 
                                dokterfaskes={faskesDokter} 
                                dokterfaskestotal={faskesDokterTotal} 
                                handleRefresh={handleRefresh} 
                                handleSearchKeyUp={handleSearchKeyUp}
                                handleSelectChange={handleSelectChange}
                                handleSelectPageNum={handleSelectPageNum}
                                handleMovePage={handleMovePage}
                                handleDelete={handleDelete}
                                filterData={filterData}
                                {...props}/>
                            
                        </div> 
                        <div label="Tarif Konsultasi"> 
                            <div className="antialiased font-sans bg-white">
                                <div className="container mx-auto px-4 sm:px-8">
                                    <div className="py-2">
                                        <h2 className="font-semibold text-lg mt-5">Tambahkan Tarif</h2>
                                        <hr className="mt-2"/>
                                        <div className="flex flex-col mb-4 justify-start">

                                            <div className="flex flex-col-6 gap-4 mt-4">
                                                <SelectSearch 
                                                    options={optionFaskes}
                                                    getOptions={handleSelectSearch} 
                                                    onChange={(value) => { setSelectedFaskes(value) }}
                                                    value={selectedFaskes} 
                                                    name="faskes" 
                                                    placeholder="Cari nama faskes" 
                                                    search 
                                                    emptyMessage="Faskes tidak ditemukan"/>
                                            
                                                <SelectSearch 
                                                    options={tarifkategori}
                                                    onChange={(value) => { setSelectedTipe(value) }}
                                                    value={selectedTipe} 
                                                    name="tipe" 
                                                    placeholder="Kategori"/>

                                                <input placeholder="Satuan Menit"
                                                    type="number"
                                                    value={menit}
                                                    onChange={(event) => { setMenit(event.target.value) }}
                                                    className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" 
                                                     />

                                                <input placeholder="Harga"
                                                    type="number"
                                                    value={harga}
                                                    onChange={(event) => { setHarga(event.target.value) }}
                                                    className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" 
                                                    />

                                                <span className="sm:ml-3 self-end">
                                                    <button  onClick={handleAddTarif} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                        <FontAwesomeIcon icon={faPlus} className="mr-2"/>Tambahkan
                                                    </button>
                                                </span>
                                                <span className="sm:ml-3 self-end">
                                                    <button  onClick={() => { history.goBack() }} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>Kembali
                                                    </button>
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DetailDokterComponents.Tarif 
                                doktertarif={tarifDokter} 
                                doktertariftotal={tarifDokterTotal} 
                                handleRefresh={handleRefreshTarif} 
                                handleSearchKeyUp={handleSearchKeyUp}
                                handleSelectChange={handleSelectChange}
                                handleSelectPageNum={handleSelectPageNum}
                                handleMovePage={handleMovePage}
                                handleDelete={handleDeletePaket}
                                filterData={filterData}
                                {...props}/>
                        </div> 
                    </Tabs>
                    
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default DokterDetail;