import React from 'react';
import PropTypes from 'prop-types';

const Tab = props => {
    Tab.propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    const onClick = () => {
        const { label, onClick } = props
        onClick(label)
    }

    let className = 'tab-list-item';

    if (props.activeTab === props.label) {
      className += ' tab-list-active';
    }

    return (
        <li
          className={className}
          onClick={onClick}
        >
          {props.label}
        </li>
      );

}

export default Tab