import React from 'react';

const DtBreadCrumb = props => {
    const classLink = props.garing ? "text-gray-500" : "text-blue-400";
    return (
        <span className={classLink}>{props.title} {props.garing ? ' / ' : ''}</span>
    )
}

const BreadCumb = props => {
    const cum = props.data;
    return (
        <div className="text-sm">
            {cum.map((title, i) => {     
                return (
                        (i+1) === cum.length ? 
                        (
                            <DtBreadCrumb key={i} title={title} garing={false}/>
                        ):(
                            <DtBreadCrumb key={i} title={title} garing={true}/>
                        )
                    
                ) 
            })}
        </div>
    )
}

export default BreadCumb;