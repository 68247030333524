import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import SelectInput from '../../atoms/SelectInput';
import Button from '../../atoms/button';
import {ManualPaging,ManualTD,ManualTH} from '../DataTable';

const DokterFaskes = props => {
    DokterFaskes.propTypes = {
        dokterfaskes: PropTypes.instanceOf(Array).isRequired,
        dokterfaskestotal: PropTypes.number.isRequired,
        filterData: PropTypes.instanceOf(Object).isRequired,
        handleRefresh: PropTypes.func.isRequired,
        handleSearchKeyUp: PropTypes.func.isRequired,
        handleSelectChange: PropTypes.func.isRequired,
        handleSelectPageNum: PropTypes.func.isRequired,
        handleMovePage: PropTypes.func.isRequired,
        handleDelete: PropTypes.func.isRequired
    };

    const TableBody = (props) => {
        const data = props.data
        const index = props.index+1
        const handleDelete = props.handleDelete
        return (
            
            <tr onClick={() => {}}>
                <ManualTD>
                    <div className="flex items-center">
                        <p className="text-gray-900 whitespace-no-wrap">
                            {index}
                        </p>
                    </div>
                </ManualTD>
                <ManualTD>
                    <div className="flex items-center">
                        <p className="text-gray-900 whitespace-no-wrap">
                            {data.faskes_code}
                        </p>
                    </div>
                </ManualTD>
                <ManualTD>
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            <p className="text-gray-900 whitespace-no-wrap font-semibold">{data.nama}</p>
                            <p className="text-gray-900 whitespace-no-wrap">{data.alamat}</p>
                            <p className="text-gray-900 whitespace-no-wrap">{data.notelp_display}</p>
                        </div>
                      </div>
                    </div>
                </ManualTD>
                <ManualTD>
                    <p className="text-gray-900 whitespace-no-wrap">
                        {data.coordinate}
                    </p>
                </ManualTD>
                <ManualTD>
                    <p className="text-gray-900 whitespace-no-wrap">
                        {data.online}
                    </p>
                </ManualTD>
                <ManualTD>
                    <Button addclass="bg-red-500 text-white font-bold" 
                            onClick={() => {handleDelete(data.user_id,data.faskes_code)}}
                            ><FontAwesomeIcon icon={faTrash}/></Button>
                </ManualTD>
            </tr>
              
        )
    }

    const filterData = props.filterData
    const dokterfaskestotal = props.dokterfaskestotal

    useEffect(() => {

    },[filterData])

    const { handleRefresh, handleSelectChange, handleSearchKeyUp, handleSelectPageNum, handleMovePage } = props

    return (
        <div className="antialiased font-sans bg-white">
            <div className="container mx-auto px-4 sm:px-8">
                <div className="py-4">
                    <div className="flex flex-row justify-between items-center">
                        <div>
                            <Button addclass="bg-green-500 text-white font-bold" 
                            onClick={handleRefresh}
                            ><FontAwesomeIcon icon={faSyncAlt}/> <span className='pl-2'>Refresh</span></Button>
                        </div>
                        <div className="my-2 flex sm:flex-row flex-col">
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['Semua','ID Faskes', 'Nama Faskes']}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className="block relative">
                                <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                                    <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                                        <path
                                            d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                                        </path>
                                    </svg>
                                </span>
                                <input placeholder="Search"
                                    className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" 
                                    onKeyUp={handleSearchKeyUp} 
                                />
                            </div>
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['10','20','50','100']}
                                    onChange={handleSelectPageNum}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <ManualTH>No</ManualTH>
                                        <ManualTH>Faskes ID</ManualTH>
                                        <ManualTH>Nama Faskes</ManualTH>
                                        <ManualTH>Kordinat</ManualTH>
                                        <ManualTH>Status Online</ManualTH>
                                        <ManualTH>Aksi</ManualTH>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.dokterfaskes.length > 0 ?
                                            props.dokterfaskes.map((element,index) => {
                                                return (
                                                    <TableBody  index={index} data={element} key={element.id} {...props}/>
                                                )
                                            })
                                        : <tr><td align="center" colSpan={6}>Tidak ada data.</td></tr>
                                    }
                                </tbody>
                            </table>
                            <ManualPaging 
                              pagenum={filterData.pagenum}  
                              pagesize={filterData.pagesize}
                              totalContent = {dokterfaskestotal}
                              handleMovePage={(e) => handleMovePage(e,'pagenum')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default DokterFaskes