import React, { useState, useEffect } from 'react'
import Button from '../../atoms/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown, faSortAmountUp, faSearch } from '@fortawesome/free-solid-svg-icons'
import SelectInput from '../../atoms/SelectInput'
import {FORM_INPUT_UPDATE} from '../../../containers/pages/Laporan/LogFaskes'
import { useDispatch } from 'react-redux'
import InputIcon from '../../atoms/InputIcon'

const ManualTable = (props) => {
    return (
        <table className="min-w-full leading-normal">
            {props.children}
        </table>
    )
}
const ManualTHEAD = (props) => {
    return (
        <thead>
            {props.children}
        </thead>
    )
}
const ManualTBODY = (props) => {
    return (
        <tbody>
            {props.children}
        </tbody>
    )
}


const ManualTR = (props) => {
    return (
        <tr>
            {props.children}
        </tr>
    )
}

const ManualTH = (props) => {
    return (
        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 uppercase items-center">
            <div className={props.style}>
                {props.children}
            </div>
        </th>
    )
}
const ManualTD = (props) => {
    return (
        <td className="px-3 py-1 border-b border-gray-200 bg-white text-xs"
        {...props}
        >
            {props.children}
        </td>
    )
}

const ManualPaging = (props) => {
    return (
        <div
            className="px-5 py-2 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
            <span className="text-xs xs:text-sm text-gray-900">
                Showing {
                    (props.totalContent > 0
                    ? 
                    (parseInt(props.pagenum)*props.pagesize)+1
                    : 0)
                } to {
                    (parseInt(props.pagenum)*props.pagesize)+props.pagesize >= props.totalContent
                    ? props.totalContent
                    : (parseInt(props.pagenum)*props.pagesize)+props.pagesize
                } of {props.totalContent} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
                <button
                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l" id='prev_page'  
                    onClick={() => props.handleMovePage('prev')}>
                    Prev
                </button>
                <button
                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r" 
                    onClick={() => props.handleMovePage('next')} id='next_page'>
                    Next
                </button>
            </div>
        </div>
    )
}

const ManualPaging2 = (props) => {

    
    const [value, setValue] = useState()
    
    let numbers = []
    for(let i = 0; i <= Math.floor(props.totalContent / props.pagesize); i++) {
        numbers.push(Number(i))
    }

    useEffect(() => {
        console.log(props.pagenum)
        setValue(props.pagenum + 1)
    }, [props.pagenum])

    const _maxLength = () => {
        // console.log(numbers)
        // return
        const numb = parseInt(value) 
        const max = parseInt(numbers.length)
        const min = parseInt(numbers[0])
        console.log('cek',min)
        if(value !== undefined){
            if(numb > max){
                console.log('max',max)
                console.log('lebih')
                props.paginate(max - 1)
                setValue(max)
            } else if(numb < (min + 1)){
                console.log('kurang')
                props.paginate(min)
                setValue(min + 1)
            } else {
                console.log('cukup')
                props.paginate(numb - 1)
                setValue(numb)
            }
        }
    }

    return (
        <div
            className="px-5 py-2 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between         ">
            <div className="inline-flex mt-2 xs:mt-0">
                <button
                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l" id='prev_page'  
                    onClick={(e) => {
                        props.handleMovePage('prev')
                        setValue(value - 1)
                    }}
                    disabled={value == 1 ? true : false}
                >
                    Prev
                </button>
                <ul className="flex-row flex m-1 border">
                    <input 
                        className="w-40"
                        placeholder={numbers[0] + 1 + ' - ' + (Number(numbers.length))}
                        max={Number(numbers.length)}
                        min={numbers[0] + 1}
                        type="number"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </ul>
                <Button 
                    addclass="bg-gray-500 text-white font-bold mr-8" 
                    onClick={() => _maxLength()}
                >
                    <FontAwesomeIcon icon={faSearch}/>
                </Button>
                <button
                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r" 
                    onClick={() => {
                        props.handleMovePage('next')
                        setValue(value + 1)
                    }} 
                    id='next_page'
                    disabled={value == Number(numbers.length) ? true : false}
                >
                    Next
                </button>
            </div>
            <span className="text-xs xs:text-sm text-gray-900">
                Showing {
                    (props.totalContent > 0
                    ? 
                    (parseInt(props.pagenum)*props.pagesize)+1
                    : 0)
                } to {
                    (parseInt(props.pagenum)*props.pagesize)+props.pagesize >= props.totalContent
                    ? props.totalContent
                    : (parseInt(props.pagenum)*props.pagesize)+props.pagesize
                } of {props.totalContent} Entries
            </span>
        </div>
    )
}
ManualPaging.defaultProps = {
    pagenum : 0,
    pagesize : 0,
    totalContent : ()=>{}
}
export {
    ManualTable,
    ManualTHEAD,
    ManualTBODY,
    ManualTR,
    ManualTH,
    ManualTD,
    ManualPaging,
    ManualPaging2
}

