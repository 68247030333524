const xusername = 'hU9aBW4ZuHDRvSAf';
const xpassword = '!QZd5g7TK#Y*7e@S';
// const xurl      = 'http://192.168.1.221:8000/api/v1/';
const xurl      = 'https://online.infokes.co.id/api/v1/';
// const xurl      = 'https://online2.infokes.co.id/api/v1/';
// const xurl      = 'http://192.168.1.48:8002/api/v1/';
const app_type  = 'development';
export {
    xusername,
    xpassword,
    xurl,
    app_type
}