const initialState = {
    filter_member : [],
    filter_tipe: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MASTER':
                return{
                    ...state,
                    filter_member: action.payload.filter_member,
                    filter_tipe: action.payload.filter_tipe
                }
        default:
            return{
                ...state
            }
    }
} 

