import React,{useState,useEffect} from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Login from '../../pages/Login';
import Loading from '../../pages/Loading';
import Dashboard from '../../pages/Dashboard';
import Faskes from '../../pages/Faskes';
import FaskesDetail from '../../pages/Faskes/Detail';
import Pasien from '../../pages/Master/Pasien'
import PasienDetail from '../../pages/Master/Pasien/Detail'
import MasterDokter from '../../pages/Master/Dokter'
import DokterDetail from '../../pages/Master/Dokter/Detail'
import LaporanKonsultasi from '../../pages/Laporan/LaporanKonsultasi';
import LaporanKunjungan from '../../pages/Laporan/LaporanKunjungan';
import LogFaskes from '../../pages/Laporan/LogFaskes';
import RegistrasiDokter from '../../pages/Laporan/RegistrasiDokter'
import RegistrasiDokterDetail from '../../pages/Laporan/RegistrasiDokter/detail'
import Konfigurasi from '../../pages/AdminPanel/Konfigurasi'
import MasterPembayaran from '../../pages/Master/MetodePembayaran'
import MasterPembayaranDetail from '../../pages/Master/MetodePembayaran/Detail'
 
import PrivateRoute from '../../../utils/PrivateRoute';
import PublicRoute from '../../../utils/PublicRoute';
import * as authActions from '../../../config/redux/action/auth';
import * as handlingError from '../../../config/redux/action/handling';
import { getToken, removeUserSession } from '../../../utils/Common';
import Menu from '../../pages/Menu'


function App() {
    const [authLoading, setAuthLoading] = useState(true);
  

    const dtUser = useSelector(state => state.auth);
    const authUser = !!dtUser.isAuth;
    const dispatch = useDispatch();
    useEffect(() => {
      if(!!getToken()){
        // log('template app - cek token',[authLoading , getToken()]);
        const cekToken = async() => {
          try{
            await dispatch(authActions.checkToken());
            await setAuthLoading(false);
          }catch(error){
            await setAuthLoading(false);
            handlingError.Response('template APP - catch login',error,dispatch);
            
          }
        }
        cekToken();
      }else{
        setAuthLoading(false);
      }
    }, [dispatch]);
    const handleLogout = async() => {    
      try{
        await removeUserSession();
        await dispatch(authActions.logout());
        // log('try action auth - logout',dtUser);
      }catch(error){
        await removeUserSession();
        await dispatch(authActions.logout());
        // log('catch action auth - logout',error);

      }
      
    }
    
    if  (authLoading && !!getToken()){
      return <Loading />
    }
    return (
      
        (authLoading && !!getToken()) ? (
          <Loading />
        )
        : (
          <div className="bg-gray-100 min-h-screen">
            <BrowserRouter>
              <div>
                  <div className="content">
                    {
                      authUser  ? 
                      <Menu handleLogout={handleLogout}/>
                        : 
                      <div></div>
                    }
                  </div>
                  <div className="content">
                  <Switch>
                      <PublicRoute path="/login" component={Login} />
                      <PrivateRoute exact path="/" component={Dashboard} />
                      <PrivateRoute path="/dashboard" component={Dashboard} />
                      <PrivateRoute exact path="/faskes" component={Faskes} />
                      <PrivateRoute exact path="/faskes/:id" component={FaskesDetail} />
                      <PrivateRoute exact path="/pasien" component={Pasien} />
                      <PrivateRoute exact path="/pasien/:id" component={PasienDetail} />
                      <PrivateRoute exact path="/dokter" component={MasterDokter} />
                      <PrivateRoute exact path="/dokter/:id" component={DokterDetail} />
                      <PrivateRoute exact path="/registrasidokter" component={RegistrasiDokter} />
                      <PrivateRoute path="/registrasidokter/:id" component={RegistrasiDokterDetail} />
                      <PrivateRoute path="/laporan_konsultasi" component={LaporanKonsultasi} />
                      <PrivateRoute exact path="/laporan_kunjungan" component={LaporanKunjungan} />
                      <PrivateRoute exact path="/log_faskes" component={LogFaskes} />
                      <PrivateRoute path="/konfigurasi" component={Konfigurasi} />
                      <PrivateRoute exact path="/metodepembayaran" component={MasterPembayaran} />
                      <PrivateRoute exact path="/metodepembayaran/:id" component={MasterPembayaranDetail} />
                  </Switch>
                  </div>
              </div>
              </BrowserRouter>
          </div>
        )
    );
}
 
export default App;