import {Auth, CheckAuth} from '../../../config/services/auth-service'
import {setUserSession} from '../../../utils/Common'
import log from '../../../utils/log';

export const AUTH_AUTHENTICATE   = 'AUTH_AUTHENTICATE';
export const AUTH_LOGOUT         = 'AUTH_LOGOUT';


export const login = (username, password) => {
    log('username',[username,password]);
    return async dispatch => {
        await Auth(username, password)
        .then(result => {
            const resData = result.data;
            console.log('uhuy',resData)
            log('action auth - API Login',resData);
            dispatch(
                authenticate(
                    username,
                    resData.response.token
                )
            )
            setUserSession(resData.response.token,username);
        })
    }
}
export const checkToken = () => {
    return async dispatch => {
        await CheckAuth()
        .then(result => {
            const resData = result.data;
            log('action auth - API checkToken',resData);
        })
    }
}

export const authenticate = (username,token) => {
    return dispatch => {
        dispatch({type:AUTH_AUTHENTICATE, username:username,token:token});
    }
};
export const logout = () => {
    return dispatch => {
        dispatch({type:AUTH_LOGOUT});
    }
}