import React,{useEffect, useState} from 'react'
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

import { GET } from '../../../../config/api';
import authHeader from '../../../../config/services/auth-header';
import * as handlingError from '../../../../config/redux/action/handling';
import log from '../../../../utils/log';
import { faArrowLeft,faHospital } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Notif from '../../../../components/atoms/notif';
import TitlePage from '../../../../components/molecules/TitlePage';
import Button from '../../../../components/atoms/button';

const AnyReactComponent = (props) => 
    <div style={{width:'100%'}}>
        <FontAwesomeIcon icon={faHospital} size='3x' color='#2692ad' />
         <div>{props.text}</div>
        {/* <div  style={{width:'100%'}}>{props.lat},{props.lg}</div> */}
    </div>;

const FaskesDetail = props => {
    const id = props.location.state.id
    const history = useHistory();
    const [notify,setNotify] 	= useState({show:'hidden'});
    const [dataContent,setDataContent] 	= useState([]);
    const [loadData,setLoadData]  = useState(false);

    const dispatch = useDispatch();
    const getData = async() => {
        try{
            setLoadData(true);
            GET('web/faskes/getFaskesDetail?id='+id,authHeader())
            .then(({data}) => {
                let dataRaw = data.response;
                log('dataRaw',dataRaw);
                setDataContent(dataRaw);
                setLoadData(false);
            })
            .catch(error => {
                setLoadData(false);
                setDataContent([]);
                log('error',error);
                const messageErr =  handlingError.Response('page login - catch login',error,dispatch);
                setNotify(	{
                    type:'danger',show:'block',title:'Data Faskes',message:messageErr
                });
                setTimeout(() => {
                    setNotify({
                        ...notify
                    });
                }, 3000);
            });
        }catch(error){
            log('error',error);
        }
    }
    useEffect(() => {
        getData();
    },[id])
    var listRate = [];
    for(let i=1; i<=5; i ++){
        if(i <= parseInt(dataContent.rating_average)){
            listRate.push(<svg className="mx-1 w-4 h-4 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" key={i}><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>);
        }else{
            listRate.push(<svg className="mx-1 w-4 h-4 fill-current text-gray-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" key={i}><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>);
        }
    }
    var tampilMap = '-';
    
    if(typeof dataContent.coordinate!=='undefined'){
        const coo =  dataContent.coordinate.split(',');
        const coordinate = {
            lat: parseFloat(coo[0]),
            lng: parseFloat(coo[1])
        }

        var tampilMap = <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyA9TwlDwbhTbmFopRICGbTVAotXFUj5npY' }}
                defaultCenter={coordinate}
                defaultZoom={15}
                >
                <AnyReactComponent
                    lat={parseFloat(coo[0])}
                    lng={parseFloat(coo[1])}
                    text={dataContent.nama}
                />
                </GoogleMapReact>
            </div>;
    }
    return (
        <div className="container mx-auto">
            <Notif 
                notify = {notify}
            />
            <TitlePage
                title='Faskes Detail'
                data = {['Master Data','Faskes Detail']}
            />
                <Button 
                    addclass="bg-green-500 text-white font-bold" 
                    onClick={() => history.goBack()}
                >
                    <FontAwesomeIcon icon={faArrowLeft}/> <span className='pl-2'>Kembali</span>
                </Button>
                <div className="flex flex-row justify-between">
                    <div className="flex-1 pr-2">
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Code</p>
                            <p className="text-9xl mt-2">
                                {dataContent.code ? dataContent.code : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">nama</p>
                            <p className="text-9xl mt-2">
                                {dataContent.nama ? dataContent.nama : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Alamat</p>
                            <p className="text-9xl mt-2">
                                {dataContent.alamat ? dataContent.alamat : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Asuransi</p>
                                <span className="text-9xl mt-2 mr-6">
                                {
                                dataContent.dt_asuransi && dataContent.dt_asuransi.length > 0  ?
                                 ( 
                                <table className="border-collapse w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">No</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Nama Asuransi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataContent.dt_asuransi.map((element,i) => {
                                                return (
                                                  
                                                    <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 cursor-pointer"  key={i} >
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            {i+1}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 border border-b block lg:table-cell relative lg:static">
                                                            {element.nama_asuransi.toUpperCase()}
                                                        </td>
                                                    </tr>
                                                  
                                                )
                                            })
                                        }
                                      </tbody>
                                </table>
                                )  : '-'
                                }
                            </span>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">URL</p>
                            <p className="text-9xl mt-2">
                                {dataContent.base_url ? dataContent.base_url : null} 
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Rating</p>
                            <p className="text-9xl mt-2">
                            <span>
                                <span className="flex ">
                                    <span className="flex items-center mt-2 mb-4">
                                        {listRate}
                                    </span>
                                </span>
                            </span>
                            </p>
                        </div>
                    </div>
                    <div  className="flex-1 pl-2">
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Member</p>
                            <p className="text-9xl mt-2">
                                <span className={"px-1 py-1 rounded-sm "+(dataContent.is_member ? 'bg-green-300' : 'bg-red-300')}>
                                    {dataContent.is_member ? 'Member' : 'Bukan Member'} 
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">No Telp</p>
                            <p className="text-9xl mt-2">
                                {dataContent.notelp ? dataContent.notelp : '-'} / {dataContent.notelp_display ? dataContent.notelp_display : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Tipe</p>
                            <p className="text-9xl mt-2">
                                {dataContent.tipe_id ? dataContent.tipe_id.toUpperCase() : '-'}
                            </p>
                        </div>

                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Koordinat</p>
                            <p className="text-9xl mt-2">
                                {dataContent.coordinate ? dataContent.coordinate : null} 
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            {tampilMap}
                        </div>
                        
                    </div>
                </div>
  
        </div>
    );
}
export default FaskesDetail;