import React,{useState, useEffect,useRef} from 'react';
import {useDispatch} from 'react-redux';

import {xusername,xpassword} from '../../../env';
import * as authActions from '../../../config/redux/action/auth';
import * as handlingError from '../../../config/redux/action/handling';
import log from '../../../utils/log';
import Button from '../../../components/atoms/button';
import Notif from '../../../components/atoms/notif';

const Login = props => {
	const [loading,setLoading] 	= useState(false);
	const [cekLogin,setCekLogin] 	= useState(false);
	const [notify,setNotify] 	= useState({show:'hidden'});
	const [state, setState]		= useState({
		username : '',
		password : ''
	})
	const handleChange = (e) => {
		const {id,value}	= e.target;
		setState(prevState => ({
			...prevState,
			[id]	: value
		}))
	}

	const dispatch = useDispatch();

	const handleLogin = async() => {
		setLoading(true);
		setCekLogin(true);
		let action;
		action = authActions.login(state.username,state.password)
		try{
			const dispatchLogin = await dispatch(action);
			setCekLogin(false);
			log('page login - try login','success',dispatchLogin);
			props.history.push('/dashboard');
		}catch(error){
			setCekLogin(false);
			const messageErr = await handlingError.Response('page login - catch login',error);
			setLoading(false);
			setNotify(	{
				type:'danger',show:'block',title:'Login',message:messageErr
			});
			setTimeout(() => {
				setNotify({
					...notify
				});
				setLoading(false);
			}, 3000);
		}
	}
	  
	return (
		<div className='bg-blue-400 h-screen w-screen '>
			<Notif 
				notify = {notify}
			/>
			
			<div className='flex flex-col items-center flex-1 h-full justify-center px-4 sm:px-0'>
				<div className="flex rounded-lg shadow-lg w-full sm:w-3/4 lg:w-1/2 bg-white sm:mx-0">
					<div className="flex flex-col w-full md:w-1/9 px-4 md:py-20 py-5">
						<div className="flex flex-col flex-1 justify-center">
							<h1 className="text-2xl text-center font-thin">Infokes Mobile Dashboard 1.0</h1>
							<form>
							<div className="w-full mt-4">
								<div className="flex flex-col">
									<input className="tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500" id="username" type="text" placeholder="Your Username"required 
									onChange={handleChange}
									/>
								</div>
								<div className="flex flex-col">
									<input className="tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500" id="password" type="password" placeholder="Your Password"required 
									onChange={handleChange}/>
								</div>
								<div className="flex flex-col mt-4">
									<Button 
									type='submit'
									addclass="bg-blue-500 text-white font-bold"
									disabled={loading} 
									onClick={handleLogin}
									>
										{loading ? 'Loading ...' : 'Login'}
									</Button>
								</div>
            						
							</div>
							</form>
						</div>
					</div>
					<div className="hidden md:block md:w-1/2 rounded-r-lg image-login">
					</div>
				</div>
			</div>
		</div>
	);
}
export default Login;