import {AUTH_AUTHENTICATE, AUTH_LOGOUT} from '../action/auth'
import {getUser,getToken} from '../../../utils/Common';
// import log from '../../../utils/log';

const initialState = {
    token       : getToken(),
    username    : getUser(),
    isAuth      : getToken() ? true : false
}

export default (state = initialState, action) => {
    switch(action.type){
        case AUTH_AUTHENTICATE:
            return {
                ...state,
                token   : action.token,
                username: action.username,
                isAuth  : (action.token ? true : false)
            }
        case AUTH_LOGOUT:
            return {
                ...state,
                token   : '',
                username: '',
                isAuth  : false
            }
        default : 
            return state;
    }
}