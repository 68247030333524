import React from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'

const Modal = props => {
    
    Modal.propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        btnCancelText: PropTypes.string.isRequired,
        btnOkText: PropTypes.string.isRequired
    };

    if (!props.show) {
        return null
    }

    const setIconBg = () => {
      const iconBg = props.color !== undefined ? 
        <div className={"mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-"+props.color+"-100 sm:mx-0 sm:h-10 sm:w-10"}>
          <FontAwesomeIcon icon={faExclamation}/>
        </div>:
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <FontAwesomeIcon icon={faExclamation}/>
        </div>
      return iconBg
    }

    const setBtnBg = () => {
      const btnBg = props.color !== undefined ? 
      <button onClick={props.onSubmit} type="button" className={"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-"+props.color+"-600 text-base font-medium text-white hover:bg-"+props.color+"-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"}>
        {props.btnOkText}
      </button>
      :<button onClick={props.onSubmit} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
        {props.btnOkText}
      </button>
      return (btnBg)
    }
    

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
        
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  {setIconBg()}
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      {props.title}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {props.body}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {setBtnBg()}
                <button onClick={props.onClose} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  {props.btnCancelText}
                </button>
              </div>
            </div>
          </div>
        </div>        
    )
}

export default Modal;