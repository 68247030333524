import React,{ useEffect,useState } from 'react';
import Notif from '../../../../../components/atoms/notif';
import TitlePage from '../../../../../components/molecules/TitlePage';
import { useHistory } from 'react-router-dom';
import Modal from '../../../../../components/molecules/Modal'
import Switch from '../../../../../components/atoms/Switch'
import  * as MetodePembayaranActions from '../../../../../config/redux/action/metodepembayaran'
import { useStore, useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons'
// import Switch from 'react-input-switch';

const MasterPembayaranDetail = props => {
    
    // const [detailDokter, setDetailDokter] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [titleModal, setTitleModal] = useState("")
    const [bodyModal, setBodyModal] = useState("")
    const [btnCancelText, setBtnCancelText] = useState("Batal")
    const [btnOkText, setBtnOkText] = useState("Ok")
    const [modalColor, setModalColor] = useState("green")
    const [modalOnSubmit, setModalOnSubmit] = useState(() => () => console.log(''))
    const [notify,setNotify] 	= useState({show:'hidden'});
    const [statusAndroid, setStatusAndroid] = useState(false);
    const [statusIos, setStatusIos] = useState(false);
    const [statusKunjungan, setStatusKunjungan] = useState(false);
    const [statusKonsultasi, setStatusKonsultasi] = useState(false);
    const [code, setCode] = useState("")
    const [nama, setNama] = useState("")
    const [description, setDescription] = useState("")
    const [group, setGroup] = useState("")
    const [tarifAdmKunjungan, setTarifAdmKunjungan] = useState(0)
    const [tarifAdmKonsultasi, setTarifAdmKonsultasi] = useState(0)
    const [tipeFaskes, setTipeFaskes] = useState("")
    const dispatch = useDispatch()

    const history = useHistory()
    
    const id = props.location.state.id
    const store = useStore()
    const detail = useSelector((state) => state.metodepembayaran.detail)
    
    var arrTipeFaskes = ["puskesmas", "klinik"];

    const loadDetail = async () => {
        await dispatch(MetodePembayaranActions.getDetil(id))
        const storedetail = store.getState().metodepembayaran.detail 
        console.log(storedetail)
        setStatusAndroid(storedetail.status_android == "1" ? true : false)
        setStatusIos(storedetail.status_ios == "1" ? true : false)
        setStatusKunjungan(storedetail.status_kunjungan == "1" ? true : false)
        setStatusKonsultasi(storedetail.status_konsultasi == "1" ? true : false)
        setNama(storedetail.value ?? "")
        setDescription(storedetail.description ?? "")
        setGroup(storedetail.payment_group ?? "")
        setCode(storedetail.code ?? "")
        setTipeFaskes(storedetail.tipe_faskes ?? "")
        setTarifAdmKunjungan(storedetail.tarif_adm_kunjungan ?? "")
        setTarifAdmKonsultasi(storedetail.tarif_adm ?? "")
    }

    useEffect(() => {
        loadDetail() 
    },[])

    const onTipeFaskesChange = (item) => {
        var tipefaskes = (tipeFaskes != null ? tipeFaskes.trim().replace(/\s/g, '').split(',') : [])
        console.log(item, tipefaskes)
        var index = tipefaskes.indexOf(item)
        if (index !== -1) {
            tipefaskes.splice(index, 1);
        } else {
            tipefaskes.push(item)
        }

        var arr = tipefaskes.filter((el) => {
            return el != "";
        })

        setTipeFaskes(arr.join() + ',')
    }

    const handleUpdate = async () => {
        const data = {
            value: nama,
            description: description,
            payment_group: group,
            tarif_adm: tarifAdmKonsultasi,
            tarif_adm_kunjungan: tarifAdmKunjungan,
            status_android: (statusAndroid ? 1 : 0),
            status_ios: (statusIos ? 1 : 0),
            status_konsultasi: (statusKonsultasi ? 1 : 0),
            status_kunjungan: (statusKunjungan ? 1 : 0),
            tipe_faskes: tipeFaskes
        }

        await dispatch(MetodePembayaranActions.updateDetail(data))

        const status = store.getState().metodepembayaran.status 
        const error_message = store.getState().metodepembayaran.error_message

        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Dokter',message:error_message
            }
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 1000);
        }

        if (status) {
            const notif = {
                type:'success',show:'block',title:'Dokter',message:"Data berhasil disimpan!"
            }
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 1000);
        }
        
    }
    
    const renderTipeFaskes = () => {
        const tipefaskes = (tipeFaskes != null ? tipeFaskes.trim().replace(/\s/g, '').split(',') : [])
        
        var render = []
        if (arrTipeFaskes.length > 0) {
            arrTipeFaskes.map((item,index) => {
                if (item != "") {
                    render.push(
                        <div className="flex flex-col" key={index}>
                            <p className="text-9xl mt-2 text-gray-500 capitalize">{item}</p>
                            <Switch isChecked={tipefaskes.includes(item)} onChange={() => { onTipeFaskesChange(item) }}/>
                        </div>
                    )
                } else {
                    render.push(
                        <div key={Date.now()}></div>
                    )
                }
                
            })
        } else {
            render.push(
                <div key={Date.now()}></div>
            )
        }

        return render
    }

    return (
    <div className="container mx-auto pb-5">
        <Notif 
				notify = {notify}
			/>
        <TitlePage
            title='Metode Pembayaran Detail'
            data = {['Metode','Pembayaran', 'Detail']}
        />

        <Modal show={showModal} title={titleModal} body={bodyModal} onClose={() => {
            setShowModal(!showModal)
        }} btnCancelText={btnCancelText} btnOkText={btnOkText} onSubmit={modalOnSubmit} color={modalColor}/>
        <div className="antialiased font-sans bg-gray">
            <div className="container">
                <div className="py-0">
                    <div className="grid grid-cols-2 gap-4 mt-10">
                        <div className="grid grid-cols-1 gap-1 content-start">
                            <h2 className="font-semibold text-lg">Informasi Metode Pembayaran</h2>
                            <hr className="mt-2"/>
                            <div className="grid grid-cols-2 shadow bg-white mt-5 rounded-md p-5">
                                <div className="">
                                    <p className="text-9xl text-gray-500">Kode: 
                                    <span className="px-2 inline-flex text-lg leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                        {code}
                                    </span>
                                    </p>
                                </div>
                                <div className="justify-self-end">
                                    <button  onClick={() => { history.goBack() }} type="button" className="self-end inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>Kembali
                                    </button>
                                </div>
                            </div>

                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6">
                                <p className="text-9xl mt-2 text-gray-500">Nama Pembayaran</p>
                                <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: Mandiri Virtual Account"
                                onChange={(event) => {
                                    setNama(event.target.value)
                                }} value={nama}/>

                                <p className="text-9xl mt-2 text-gray-500">Deskripsi</p>
                                <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: Pembayaran via Mandiri Virtual Account"
                                onChange={(event) => {
                                    setDescription(event.target.value)
                                }} value={description}/>

                                <p className="text-9xl mt-2 text-gray-500">Grup Pembayaran</p>
                                <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: Ipaymu"
                                onChange={(event) => {
                                    setGroup(event.target.value)
                                }} value={group}/>

                                <p className="text-9xl mt-2 text-gray-500">Tarif Konsultasi</p>
                                <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: Ipaymu"
                                onChange={(event) => {
                                    setTarifAdmKonsultasi(event.target.value)
                                }} value={tarifAdmKonsultasi}/>

                                <p className="text-9xl mt-2 text-gray-500">Tarif Kunjungan</p>
                                <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: Ipaymu"
                                onChange={(event) => {
                                    setTarifAdmKunjungan(event.target.value)
                                }} value={tarifAdmKunjungan}/>

                                <div className="grid grid-cols-4 gap-2 mt-2 mb-2">
                                    <div className="flex flex-col">
                                        <p className="text-9xl mt-2 text-gray-500">Status Android</p>
                                        <Switch isChecked={statusAndroid} onChange={() => {
                                            setStatusAndroid(!statusAndroid)
                                        }}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-9xl mt-2 text-gray-500">Status IOS</p>
                                        <Switch isChecked={statusIos} onChange={() => {
                                            setStatusIos(!statusIos)
                                        }}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-9xl mt-2 text-gray-500">Status Kunjungan</p>
                                        <Switch isChecked={statusKunjungan} onChange={() => {
                                            setStatusKunjungan(!statusKunjungan)
                                        }}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-9xl mt-2 text-gray-500">Status Konsultasi</p>
                                        <Switch isChecked={statusKonsultasi} onChange={() => {
                                            setStatusKonsultasi(!statusKonsultasi)
                                        }}/>
                                    </div>
                                </div>
                                <div className="py-3"><hr/></div>
                                <p className="text-9xl mt-2 text-gray-500">Tipe Faskes</p>
                                <div className="grid grid-cols-4 gap-2">
                                    {
                                        renderTipeFaskes()
                                    }
                                </div>
                                <div className="py-3"><hr/></div>
                                <span className="sm:ml-3 self-end">
                                    <button  onClick={handleUpdate} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <FontAwesomeIcon icon={faSave} className="mr-2"/>Simpan
                                    </button>
                                </span>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default MasterPembayaranDetail;