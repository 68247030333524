import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2'
import PropTypes from 'prop-types';

const BarChart = props => {

    BarChart.propTypes = {
        data: PropTypes.instanceOf(Array).isRequired,
        title: PropTypes.string.isRequired,
        labels: PropTypes.instanceOf(Array).isRequired,
        label: PropTypes.string.isRequired,
    };

    const settings = {
        labels: props.labels,
        datasets: [
          {
            label: props.label,
            backgroundColor: 'rgba(	96, 207, 65,0.2)',
            borderColor: 'rgba(	96, 207, 65,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba( 96, 207, 65,0.4)',
            hoverBorderColor: 'rgba(96, 207, 65,1)',
            data: props.data
          }
        ]
    };

    const datanya = () => {
        // console.log('cek',props.data)
        const data = props.data
        let render = []
        data.map((index, i) => {
            render.push(
                // console.log(console.log(index))
            )
        })
        return render
    }

    useEffect(() => {
        
    },[])

    return (
        <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{props.title}</h3>
            {/* {datanya()} */}
            <Bar
                data={settings}
                width={100}
                height={200}
                options={{
                    maintainAspectRatio: false
                }}
            />
        </div>
    )
}

export default BarChart