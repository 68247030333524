import React from 'react';
const Button = props => {
    return (
    	<button
		  className={"inline-flex items-center justify-center px-4 py-2 text-sm leading-5 rounded-md border font-medium shadow-sm transition ease-in-out duration-150 focus:outline-none "+ props.addclass+"focus:shadow-outline hover:text-gray-100"} 
		  {...props}
	    > {props.children}
	    </button>
    );
}
export default Button;