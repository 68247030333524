import { GET, POST } from '../../api';
import authHeader from '../../services/auth-header'
import * as handlingError from './handling'

export const getSettings = () => {
    return async dispatch => {
        dispatch({
            type: 'GET_SETTINGS',
            payload: {
                items: []
            }
        })
        return GET('web/settings/getItems',authHeader())
            .then(({data}) => {
                const settings = data.response.settings
                dispatch({
                    type: 'GET_SETTINGS',
                    payload: {
                        items: settings
                    }
                })
            })
            .catch(error => {
                console.log(error)

                handlingError.Response('get settings', error, dispatch)
            });
    }
}

export const editSetting = (editData) => {
    return async dispatch => {
        
        dispatch({
            type: 'START_EDIT_SETTING',
            payload: {
                status_edit_setting: false,
                error_message: ''
            }
        })

        return POST('web/settings/editSetting', editData , authHeader())
            .then(({data}) => {
                const response = data.response 
                console.log(response.status)
                dispatch({
                    type: 'POST_EDIT_SETTING',
                    payload: {
                        status_edit_setting: response.status
                    }
                })
            })
            .catch(error => {
                console.log(error)
                const messageErr = handlingError.Response('post edit setting', error, dispatch)
                console.log(messageErr)
                dispatch({
                    type: 'POST_EDIT_SETTING_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}

export const addSetting = (editData) => {
    return async dispatch => {
        
        dispatch({
            type: 'START_ADD_SETTING',
            payload: {
                status_add_setting: false,
                error_message: ''
            }
        })

        return POST('web/settings/addSetting', editData , authHeader())
            .then(({data}) => {
                const response = data.response 
                
                dispatch({
                    type: 'POST_ADD_SETTING',
                    payload: {
                        status_add_setting: response.status
                    }
                })
            })
            .catch(error => {
                console.log(error)
                const messageErr = handlingError.Response('post add setting', error, dispatch)
                console.log(messageErr)
                dispatch({
                    type: 'POST_ADD_SETTING_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}

export const deleteSetting = (deleteData) => {
    return async dispatch => {
        
        dispatch({
            type: 'START_DELETE_SETTING',
            payload: {
                status_delete_setting: false,
                error_message: ''
            }
        })

        return POST('web/settings/deleteSetting', deleteData , authHeader())
            .then(({data}) => {
                const response = data.response 
                
                dispatch({
                    type: 'POST_DELETE_SETTING',
                    payload: {
                        status_delete_setting: response.status
                    }
                })
            })
            .catch(error => {
                console.log(error)
                const messageErr = handlingError.Response('post delete setting', error, dispatch)
                console.log(messageErr)
                dispatch({
                    type: 'POST_DELETE_SETTING_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}