import React from 'react';
import BreadCumb from '../../atoms/BreadCrumb';
const TitlePage = props => {
    return (
        <div className="flex flex-row justify-between items-center py-1">
            <div className="text-2xl antialiased">{props.title}</div>
            <BreadCumb 
                data={props.data}
            />
        </div>
    )
}

export default TitlePage;