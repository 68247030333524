const initialState = {
    detaildokter: null,
    status: false,
    error_message: null,
    berkas: null,
    dokterfaskes: [],
    dokterfaskestotal: 0,
    listfaskes: [],
    listfaskestotal: 0,
    status_add_faskes: false,
    status_delete_faskes: false,
    status_delete_tarif: false,
    doktertarif: [],
    doktertariftotal: 0
}

export default (state = initialState, action) => {
    switch(action.type){
        case 'GET_MASTER_DOKTER_DETAIL':
            return {
                ...state,
                detaildokter: action.payload.detaildokter,
                berkas: action.payload.berkas
            }
        case 'GET_FASKES_DOKTER':
            return {
                ...state,
                dokterfaskes: action.payload.dokterfaskes,
                dokterfaskestotal: action.payload.dokterfaskestotal
            }
        case 'GET_MASTER_DOKTER_LIST_FASKES':
            return {
                ...state,
                listfaskes: action.payload.listfaskes,
                listfaskestotal: action.payload.listfaskestotal
            }
        case 'POST_ADD_FASKES':
            return {
                ...state,
                status_add_faskes: action.payload.status_add_faskes
            }
        case 'POST_ADD_FASKES_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        case 'START_POST_ADD_FASKES':
            return {
                ...state,
                status_add_faskes: action.payload.status_add_faskes,
                error_message: action.payload.error_message
            }
        case 'POST_DELETE_FASKES':
            return {
                ...state,
                status_delete_faskes: action.payload.status_delete_faskes
            }
        case 'POST_DELETE_FASKES_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        case 'START_POST_DELETE_FASKES':
            return {
                ...state,
                status_delete_faskes: action.payload.status_delete_faskes,
                error_message: action.payload.error_message
            }
        case 'GET_TARIF_DOKTER':
            return {
                ...state,
                doktertarif: action.payload.doktertarif,
                doktertariftotal: action.payload.doktertariftotal
            }
        case 'POST_DELETE_TARIF':
            return {
                ...state,
                status_delete_tarif: action.payload.status_delete_tarif
            }
        case 'POST_DELETE_TARIF_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        case 'START_POST_DELETE_TARIF':
            return {
                ...state,
                status_delete_tarif: action.payload.status_delete_tarif,
                error_message: action.payload.error_message
            }
        case 'POST_ADD_TARIF':
            return {
                ...state,
                status_add_tarif: action.payload.status_add_tarif
            }
        case 'POST_ADD_TARIF_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        case 'START_POST_ADD_TARIF':
            return {
                ...state,
                status_add_tarif: action.payload.status_add_tarif,
                error_message: action.payload.error_message
            }
        default : 
            return state;
    }
}