import log from '../../../utils/log';
import { removeUserSession } from '../../../utils/Common';
import * as authActions from '../../../config/redux/action/auth';


export const Response = (asal,error,dispatch) => {
    if (error.response) {
    	// Request made and server responded
    	// console.log(error.response.data);
    	// console.log(error.response.status);
        // console.log(error.response.headers);
        log('response - ' + asal,error.response.data);
        const err = error.response.data;
        
        if(typeof err != "undefined" && typeof err.response != "undefined" && typeof err.response.type != "undefined" && err.response.type==='token_error'){
        removeUserSession();
            dispatch(authActions.logout());
        }
        return (typeof err.message !== 'undefined') ? err.message : err.response.message;
    } else if (error.request) {
    	// The request was made but no response was received
        log('request - ' + asal,error.request);
        return 'Terjadi kesalahan pada sistem';
    } else if (typeof error.response.type != "undefined" && error.response.type === 'error_validation') {
        const messages = error.response.message 
        return messages
    }else {
    	// Something happened in setting up the request that triggered an Error
        log('not request n response - ' + asal,((typeof error.message != 'undefined') ? error.message : error));
        return (typeof error.message !== 'undefined') ? error.message : error;
    }
}
