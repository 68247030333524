import React from 'react';


const SelectInput = props => {
    // console.log('cek',props)
    return (
        <div className="relative">
            <select
                className={"h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-1 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "+props.addClass+""}
                onChange={(e) => props.onChange(e)}
                {...props}
                value = {props.value}
            >
                {
                    props.data.map((key,i) => {
                        // console.log('key',key)
                        var value = (props.valueTipe==='key' 
                            ? i 
                            : (props.valueTipe==='keyBulan' 
                                    ? parseInt(i)+1
                                    : key.toString().toLowerCase().replace(" ","_")
                                )
                        );
                        return (
                            <option key={i} value={value} >{key}</option>
                        )
                    })
                }
                
            </select>
        </div>
    );
}
SelectInput.defaultProps = {
    addClass : '',
    valueTipe : 'value',
    data : []
}
export default SelectInput;