import React,{ useEffect,useState } from 'react';
import Notif from '../../../../components/atoms/notif';
import TitlePage from '../../../../components/molecules/TitlePage';
import Modal from '../../../../components/molecules/Modal';
import * as KonfigurasiActions from '../../../../config/redux/action/konfigurasi'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'

const Konfigurasi = props => {
    
    const [notify,setNotify] 	= useState({show:'hidden'})
    const [value, setValue] = useState([])
    const [addKey, setAddKey] = useState("")
    const [addValue, setAddValue] = useState("")

    // Modal States
    const [showModal, setShowModal] = useState(false)
    const [titleModal, setTitleModal] = useState("")
    const [bodyModal, setBodyModal] = useState("")
    const [btnCancelText, setBtnCancelText] = useState("Batal")
    const [btnOkText, setBtnOkText] = useState("Ok")
    const [modalColor, setModalColor] = useState("green")
    const [modalOnSubmit, setModalOnSubmit] = useState(() => () => console.log(''))

    const settingItems = useSelector((state) => state.konfigurasi.items)
    const dispatch = useDispatch()
    const store = useStore()
    
    useEffect(() => {
        loadSettings()    
    },[])

    const loadSettings = () => {
        dispatch(KonfigurasiActions.getSettings())
    }

    const handleSave = async (key,nilai) => {
        console.log(key,nilai)
        console.log(value[key])

        const finalValue = typeof value[key] != "undefined" ? value[key] : nilai
        const data = {
            label: key,
            value: finalValue
        }
        console.log(data)
        await dispatch(KonfigurasiActions.editSetting(data))

        const error_message = store.getState().konfigurasi.error_message
        const status_edit_setting = store.getState().konfigurasi.status_edit_setting
        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Konfigurasi',message:error_message
            }
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } 

        console.log('status: ', status_edit_setting)
        if (status_edit_setting) {
            const message = ["Data berhasil disimpan"]
            const notif = {
                type:'success',show:'block',title:'Konfigurasi',message:message
            }
            
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        }
    }

    const handleOnChange = (event,label) => {
        const value = event.target.value
        var assoc_pagine = new Object();
        assoc_pagine[label] = value

        setValue(prevState => ({
            ...prevState, ...assoc_pagine
        }))
    }

    const handleAdd = async () => {
        const data = {
            label: addKey,
            value: addValue
        }

        await dispatch(KonfigurasiActions.addSetting(data))

        const error_message = store.getState().konfigurasi.error_message
        const status_add_setting = store.getState().konfigurasi.status_add_setting
        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Konfigurasi',message:error_message
            }
            setNotify(notif)
            loadSettings()
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } 

        console.log('status: ', status_add_setting)
        if (status_add_setting) {
            const message = ["Data berhasil disimpan"]
            const notif = {
                type:'success',show:'block',title:'Konfigurasi',message:message
            }
            
            setNotify(notif)
            loadSettings()
            setAddKey("")
            setAddValue("")
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        }
    }

    const confirmDelete = (key,nilai) => {
        setTitleModal('Hapus Konfigurasi')
        setBodyModal("Apakah Anda yakin akan menghapus konfigurasi ini?")
        setBtnCancelText("Batal")
        setBtnOkText("Ya, Hapus")
        setModalColor("red")
        setModalOnSubmit(() => () => handleDelete(key,nilai))
        setShowModal(!showModal)
    }

    const handleDelete = async (key,nilai) => {
        const data = {
            label: key,
            value: nilai
        }
        console.log(data)
        await dispatch(KonfigurasiActions.deleteSetting(data))

        const error_message = store.getState().konfigurasi.error_message
        const status_delete_setting = store.getState().konfigurasi.status_delete_setting
        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Konfigurasi',message:error_message
            }
            setNotify(notif)
            setShowModal(false)
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }

        console.log('status: ', status_delete_setting)
        if (status_delete_setting) {
            const message = ["Data berhasil dihapus"]
            const notif = {
                type:'success',show:'block',title:'Konfigurasi',message:message
            }
            loadSettings()
            setShowModal(false)
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        } else {
            setShowModal(false)
        }
    }

    return (
    <div className="container mx-auto">
        <Notif 
				notify = {notify}
			/>
        <TitlePage
            title='Konfigurasi'
            data = {['Admin Panel','Konfigurasi']}
        />
        <Modal show={showModal} title={titleModal} body={bodyModal} onClose={() => {
            setShowModal(!showModal)
        }} btnCancelText={btnCancelText} btnOkText={btnOkText} onSubmit={modalOnSubmit} color={modalColor}/>
        <div className="antialiased font-sans bg-white">
            <div className="container mx-auto px-4 sm:px-8">
                <div className="py-8">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 items-center px-4 py-5 sm:px-6">
                            <div className="grid">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Halaman Konfigurasi
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Konfigurasi Aplikasi, Konfigurasi API (Key, Secret dsb), Konfigurasi Pembayaran dan seterusnya.
                                </p>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols sm:gap-4 items-center px-4 sm:px-6">
                            <h5 className="text-md leading-6 font-medium text-gray-900">
                                Tambah Konfigurasi
                            </h5>
                            <hr/>
                        </div>
                        
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center px-4 py-5 sm:px-6">
                            <input placeholder="Key"
                                type="text"
                                value={addKey}
                                onChange={(event) => {
                                    setAddKey(event.target.value)    
                                }}
                                className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" 
                                    />
                            <input placeholder="Value"
                                type="text"
                                value={addValue}
                                onChange={(event) => { 
                                    setAddValue(event.target.value)
                                }}
                                className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" 
                                    />
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                <button  onClick={handleAdd} type="button" className="inline-block  items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                </button>
                            </dd>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                {settingItems != undefined && settingItems.length > 0 ?
                                    settingItems.map((item,i) => {
                                        return(
                                            <div key={i} className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {item.label}
                                                </dt>
                                                <div className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                    <input type="text" className="px-4 py-3 rounded-full border-gray-400 border-2 w-full" placeholder="Nilai"
                                                    onChange={(event) => {
                                                        handleOnChange(event, item.label)
                                                    }} defaultValue={item.value} />
                                                </div>
                                                <div className="flex gap-1">
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                        <button  onClick={() => {
                                                            handleSave(item.label,item.value)
                                                        }} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                                            <FontAwesomeIcon icon={faSave} className="mr-2"/> Simpan
                                                        </button>
                                                    </dd>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                        <button  onClick={() => {
                                                            confirmDelete(item.label,item.value)
                                                        }} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                                            <FontAwesomeIcon icon={faTrash} className="mr-2"/> Hapus
                                                        </button>
                                                    </dd>
                                                </div>
                                            </div>
                                        )
                                    })
                                :null}
                            
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default Konfigurasi;