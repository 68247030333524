const initialState = {
    items: [],
    error_message: null,
    status_edit_setting: false,
    status_add_setting: false,
}

export default (state = initialState, action) => {
    switch(action.type){
        case 'GET_SETTINGS':
            return {
                ...state,
                items: action.payload.items
            }
        case 'POST_EDIT_SETTING':
            return {
                ...state,
                status_edit_setting: action.payload.status_edit_setting
            }
        case 'POST_EDIT_SETTING_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        case 'START_EDIT_SETTING':
            return {
                ...state,
                status_edit_setting: action.payload.status_edit_setting,
                error_message: action.payload.error_message
            }
        case 'POST_ADD_SETTING':
            return {
                ...state,
                status_add_setting: action.payload.status_add_setting
            }
        case 'POST_ADD_SETTING_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        case 'START_ADD_SETTING':
            return {
                ...state,
                status_add_setting: action.payload.status_add_setting,
                error_message: action.payload.error_message
            }
        case 'POST_DELETE_SETTING':
            return {
                ...state,
                status_delete_setting: action.payload.status_delete_setting
            }
        case 'POST_DELETE_SETTING_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        case 'START_DELETE_SETTING':
            return {
                ...state,
                status_delete_setting: action.payload.status_delete_setting,
                error_message: action.payload.error_message
            }
        default : 
            return state;
    }
}