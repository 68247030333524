import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle,faExclamationCircle,faCheckCircle,faInfoCircle } from '@fortawesome/free-solid-svg-icons'
// import log from '../../../utils/log';
const PMessage = (props) => {
    return(
        <p className="font">{props.data}</p>
    );
}
const Notif = props => {
    let icon        = faCheckCircle;
    let coloricon   = 'bg-teal-100 border-teal-500';
    let type        = props.notify.type ? props.notify.type : 'success';
    
    if(type==='info'){
        icon = faInfoCircle;
        coloricon = 'bg-blue-100 border-blue-500';
    }else if(type==='warning'){
        icon = faExclamationCircle;
        coloricon = 'bg-orange-100 border-orange-500';
    }else if(type==='danger'){
        icon = faExclamationTriangle;
        coloricon = 'bg-red-100 border-red-500';
    }else if(type==='success'){
        icon = faCheckCircle;
        coloricon = 'bg-teal-100 border-teal-500';
    }
    let arrayMessage = props.notify.message ? props.notify.message : [];
    return (
    	<div 
            className={" "+ coloricon +" border-t-4 rounded-b text-teal-900 px-4 py-3 shadow-md m-3 z-10 "+ props.notify.show+" fixed right-0 bottom-0 "} 
            role="alert"
            >
            <div className="flex">
                <div>
                <FontAwesomeIcon icon={icon} size="3x" className="mr-3"/>
                </div>
                <div>
                    <p className="font-bold">{props.notify.title}</p>
                    {
                    (Array.isArray(arrayMessage))
                     ? 
                        arrayMessage.map((val, i) => (
                            <PMessage key={i} data={val} />
                        ))
                    : 
                        <PMessage key={1} data={arrayMessage} />
                    }
                </div>
            </div>
        </div>
    );
}
Notif.defaultProps = {
    notify : {title:'Title',message:'Message',show:'hidden'}
};
  
export default Notif;