import React,{ useEffect,useState,useCallback,useReducer } from 'react';
import authHeader from '../../../../config/services/auth-header';
import { POST } from '../../../../config/api';
import {useDispatch} from 'react-redux';

import * as handlingError from '../../../../config/redux/action/handling';
import Notif from '../../../../components/atoms/notif';
import {changeddmmyyyy,formatMoney} from '../../../../utils/formatDate'
import {filterTipe,filterTahun,filterBulan,filterTgl,getLastDate} from '../../../../utils/filterData'

import TitlePage from '../../../../components/molecules/TitlePage';
import {ManualTable,ManualTHEAD,ManualTBODY,ManualTR,ManualTD,ManualTH,ManualPaging} from '../../../../components/molecules/DataTable';
import log from '../../../../utils/log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import SelectInput from '../../../../components/atoms/SelectInput';
import InputIcon from '../../../../components/atoms/InputIcon';
import Button from '../../../../components/atoms/button';
import LoadingLittle from '../../LoadingLittle'

const TableBody = (props) => {
    return (
        <tr className="items-start">
            <ManualTD>
                <div className="flex items-start">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {props.data.id}
                    </p>
                </div>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.tanggal_kunjungan}</p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.nama_faskes}</p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.nama_pelayanan}</p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.nama_pasien}
                </p>
            </ManualTD>
            <ManualTD>
                <div className="grid grid-cols-2 gap-1">
                    <p className="text-gray-900 whitespace-no-wrap">
                        Status Pemeriksaan
                    </p>
                    <p className="text-gray-900 whitespace-no-wrap capitalize">
                        {props.data.status}
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-1">
                    <p className="text-gray-900 whitespace-no-wrap">
                        Status Pembayaran
                    </p>
                    <p className="text-gray-900 whitespace-no-wrap capitalize">
                        {props.data.status_bayar}
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-1">
                    <p className="text-gray-900 whitespace-no-wrap">
                        No Antrian
                    </p>
                    <p className="text-gray-900 whitespace-no-wrap capitalize">
                        {props.data.reg_antrian}
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-1">
                    <p className="text-gray-900 whitespace-no-wrap">
                        No Antrian Poli
                    </p>
                    <p className="text-gray-900 whitespace-no-wrap capitalize">
                        {props.data.reg_antrian_poli}
                    </p>
                </div>
            </ManualTD>
            
        </tr>
          
    )
}

const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
const FORM_INPUT_RESET  = 'FORM_INPUT_RESET';

const formReducer = (state,action) => {
    if(action.type === FORM_INPUT_UPDATE){
        const updatedValues = {
            ...state.inputValues,
            [action.input] : action.value
        }
        return {
            inputValues : updatedValues,
        }
    }else if(action.type === FORM_INPUT_RESET){
        const updatedValues = {
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
            filter_tipe     : 'harian',
            filter_tahun    : new Date().getFullYear(),
            filter_bulana   : 1,
            filter_bulanb   : new Date().getMonth() + 1,
            filter_tgla     : 1,
            filter_tglb     : getLastDate(new Date().getFullYear(),new Date().getMonth() + 1)
        }
        return {
            inputValues : updatedValues,
        }
    }
    return state;
}
function LaporanKunjungan() {
    const [dataContent,setDataContent]  = useState([]);
    const [totalContent,setTotalContent]  = useState(0);
    const [loadData,setLoadData]  = useState(false);
    const [notify,setNotify] 	= useState({show:'hidden'});
    const [showModal,setShowModal] 	= useState(false);
    const [dataDetail,setDataDetail] 	= useState([]);
    const [nilaiFilter,setNilaiFilter] 	= useState({
        totalBelum : 0,
        nilaiBelum : 0,
        totalSudah : 0,
        nilaiSudah : 0,
    });
    const [stateTransaksiDetail,setStateTransaksiDetail] 	= useState({
        loadData : false,
        id : '',
    });
    const [actionFilterTipe,setActionFilterTipe] = useState({
        harian : true,
        bulanan : false,
        tahunan : false,

    });  
    const [formFilterState, dispatchFormFilterState] = useReducer(formReducer,{
        inputValues:{
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
            filter_tipe     : 'harian',
            filter_tahun    : new Date().getFullYear(),
            filter_bulana   : 1,
            filter_bulanb   : new Date().getMonth() + 1,
            filter_tgla     : 1,
            filter_tglb     : getLastDate(new Date().getFullYear(),new Date().getMonth() + 1)
        }
    })
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
    },[formFilterState.inputValues.pagenum])
    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue) => {
            dispatchFormFilterState({
                type: FORM_INPUT_UPDATE,
                value: inputValue,
                input: inputIdentifier
            });
        },
        [dispatchFormFilterState]
    );
    const handleFilterTipe = (e) => {
        let filter = e.target.value;
        if(filter==='tahunan'){
            setActionFilterTipe({
                harian : false,
                bulanan : false,
                tahunan : true,
            });
        }else if(filter==='bulanan'){
            setActionFilterTipe({
                harian : false,
                bulanan : true,
                tahunan : false,
            });
        }else if(filter==='harian'){
            setActionFilterTipe({
                harian : true,
                bulanan : false,
                tahunan : false,
            });
        }
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:filter,
            input:'filter_tipe'
        })
    }
    const handleFilterFunc = (e,id) => {
        let filter = e.target.value;
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:filter,
            input:id
        })
    }
    const handleGetLaporan = () => {
        getData();
    }
    // const handleRefresh = () => {
    //     dispatchFormFilterState({
    //         type:FORM_INPUT_RESET,
    //     });
    // }
    
    const handleMovePage = async(type,id) => {
        if(type==='prev'){
            if(formFilterState.inputValues.pagenum === 0){
                // setNotify(	{
                //     type:'danger',show:'block',title:'Data Faskes',message:'Sudah dihalaman satu'
                // });
                // setTimeout(() => {
                //     setNotify({
                //         ...notify
                //     });
                // }, 3000);
            }else{
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum-1,
                    input:id
                })
            }
        }else{
            if(((parseInt(formFilterState.inputValues.pagenum+1))*formFilterState.inputValues.pagesize) <= totalContent){
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum+1,
                    input:id
                })
                
            }else{
                // setNotify(	{
                //     type:'danger',show:'block',title:'Data Faskes',message:'Halaman sudah habis'
                // });
                // setTimeout(() => {
                //     setNotify({
                //         ...notify
                //     });
                // }, 3000);
            }
            
        }
    }
    const handleDetailTransaksi = (id) => {
        setStateTransaksiDetail(prevState => ({
            ...prevState,
            loadData : prevState.id!==id && prevState.id!=='' && prevState.loadData ? prevState.loadData : !prevState.loadData,
            id : id,
        }))
    }
    const checkCheck = (data) => {
        console.log(data);
        setShowModal(true);
        setDataDetail(data);
    }
    const getData = async() => {
        try{
            log('filterData',formFilterState.inputValues);
            setLoadData(true);
            POST('web/kunjungan/getLaporanKunjungan',formFilterState.inputValues,authHeader())
            .then(({data}) => {
                let dataRaw = data.response.rows;
                let totalRaw = data.response.totalRows;
                setDataContent(dataRaw);
                setTotalContent(totalRaw);
                setLoadData(false);
            })
            .catch(error => {
                setLoadData(false);
                setDataContent([]);
                // const messageErr =  handlingError.Response('page login - catch login',error,dispatch);
                // setNotify(	{
                //     type:'danger',show:'block',title:'Data Faskes',message:messageErr
                // });
                // setTimeout(() => {
                //     setNotify({
                //         ...notify
                //     });
                // }, 3000);
            });
        }catch(error){
            log('error',error);
        }
    }
  return (
    <div className="container mx-auto">
        <Notif 
                notify = {notify}
            />
        <TitlePage
            title='Laporan Kunjungan'
            data = {['Laporan','Kunjungan']}
        />
        <div className="antialiased font-sans bg-white">
            <div className="container mx-auto px-4 sm:px-4">
                <div className="py-4 relative">
                    <div className="py-0">Filter Detail : </div>
                    <div className="flex flex-row pb-3 pt-1 ">
                        <div className='w-1/5 pr-5'>
                             <SelectInput
                                id="filter_tipe"
                                data={filterTipe}
                                valueTipe='value'
                                onChange={(e) => handleFilterTipe(e)}
                            /> 
                        </div>
                        <div className='w-1/5 pr-5'>
                            <SelectInput 
                                id="filter_tahun"
                                data={filterTahun(new Date().getFullYear())}
                                onInputChange={inputChangeHandler}
                                value={formFilterState.inputValues.filter_tahun}
                                onChange={(e) => handleFilterFunc(e,'filter_tahun')}
                             /> 
                        </div>
                        <div className={'w-1/5 pr-5 '+ (actionFilterTipe.bulanan ? 'show' : 'hidden')}>
                            <SelectInput
                                id="filter_bulana"
                                data={filterBulan}
                                valueTipe='keyBulan'
                                value={formFilterState.inputValues.filter_bulana}
                                onChange={(e) => handleFilterFunc(e,'filter_bulana')}
                            /> 
                        </div>
                        <div className={'w-1/5 pr-5 '+(actionFilterTipe.tahunan ? 'hidden' : 'show')}>
                            <SelectInput
                                id="filter_bulanb"
                                data={filterBulan}
                                valueTipe='keyBulan'
                                value={formFilterState.inputValues.filter_bulanb}
                                onChange={(e) => handleFilterFunc(e,'filter_bulanb')}
                            />
                        </div>
                        <div className={"w-2/5 flex flex-row "+ (actionFilterTipe.harian ? 'show' : 'hidden')}>
                            <div className='w-1/2 pr-5'>
                                <SelectInput
                                    id="filter_tgla"
                                    data={filterTgl(formFilterState.inputValues.filter_tahun,formFilterState.inputValues.filter_bulana)}
                                    value={formFilterState.inputValues.filter_tgla}
                                    onChange={(e) => handleFilterFunc(e,'filter_tgla')}
                                />
                            </div>
                            <div className='pr-5 pt-1 text-center'>
                                s/d
                            </div>
                            <div className='w-1/2'>
                                <SelectInput
                                    id="filter_tglb"
                                    data={filterTgl(formFilterState.inputValues.filter_tahun,formFilterState.inputValues.filter_bulana)}
                                    value={formFilterState.inputValues.filter_tglb}
                                    onChange={(e) => handleFilterFunc(e,'filter_tglb')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <div>
                            <Button 
                                addclass="bg-green-500 text-white font-bold" 
                                onClick={() => handleGetLaporan()}
                            >
                                <FontAwesomeIcon icon={faFolderOpen}/> <span className='pl-2'>Tampilkan</span>
                            </Button>
                        </div>
                        <div className="my-2 flex sm:flex-row flex-col">
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput
                                    data={['Semua','Nama Pasien','Nama Dokter','Tanggal Konsultasi']} 
                                    // value={filterData.search_key}
                                    valueTipe = 'value'
                                    onChange={(e) => handleFilterFunc(e,'search_key')}
                                 />
                            </div>
                            <div className="block relative">
                                <InputIcon 
                                    icon='search' 
                                    id='search_value'
                                    onInputChange={inputChangeHandler}
                                    initialValue={formFilterState.inputValues.search_value}
                                />
                            </div>
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['10','20','50','100']}
                                    onChange={(e) => handleFilterFunc(e,'pagesize')}
                                    // onChange={(e) => handlePageSize(e)}
                                    // value={filterData.pagesize}
                                 />
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <ManualTable>
                                <ManualTHEAD>
                                    <ManualTR>
                                        <ManualTH>ID</ManualTH>
                                        <ManualTH>Tanggal Kunjungan</ManualTH>
                                        <ManualTH>Fakses</ManualTH>
                                        <ManualTH>Nama Pelayanan</ManualTH>
                                        <ManualTH>Nama Pasien</ManualTH>
                                        <ManualTH>Keterangan</ManualTH>
                                    </ManualTR>
                                </ManualTHEAD>
                                <ManualTBODY>
                                    {
                                        (loadData) ? 
                                        <ManualTR>
                                            <ManualTD>
                                                <LoadingLittle />
                                            </ManualTD>
                                        </ManualTR>    
                                        : 
                                        dataContent.map((element) => {
                                            return (
                                                <TableBody  
                                                    data={element} 
                                                    key={element.id}
                                                />
                                            )
                                        })
                                    }
                                </ManualTBODY>
                            </ManualTable>
                            <ManualPaging 
                              pagenum={formFilterState.inputValues.pagenum}  
                              pagesize={formFilterState.inputValues.pagesize}
                              totalContent = {totalContent}
                              handleMovePage={(e) => handleMovePage(e,'pagenum')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default LaporanKunjungan;