const initialState = {
    detail: null,
    status: false,
    error_message: null,
}

export default (state = initialState, action) => {
    switch(action.type){
        case 'GET_DETAIL_METODE_PEMBAYARAN':
            return {
                ...state,
                detail: action.payload.detail,
            }
        case 'START_UPDATE_DETAIL':
            return {
                ...state,
                status: action.payload.status,
                error_message: action.payload.error_message
            }
        case 'UPDATE_DETAIL':
            return {
                ...state,
                status: action.payload.status,
                error_message: action.payload.error_message
            }
        case 'UPDATE_DETAIL_FAILED':
            return {
                ...state,
                error_message: action.payload.error_message
            }
        default : 
            return state;
    }
}