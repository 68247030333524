import { POST } from '../../api';
import authHeader from '../../services/auth-header'
import * as handlingError from './handling'

export const getDetil = (id) => {
    return async dispatch => {
        console.log('id: ', id)
        return POST('web/master/metodepembayaran/getDetail', { id: id } , authHeader())
            .then(({data}) => {
                const detail = data.response.detail 
                console.log(detail)
                dispatch({
                    type: 'GET_DETAIL_METODE_PEMBAYARAN',
                    payload: {
                        detail: detail,
                    }
                })
            })
            .catch(error => {
                console.log(error)
                handlingError.Response('post detil metode pembayaran', error, dispatch)
            });
    }
}

export const updateDetail = (dataUpdate) => {
    return async dispatch => {
        dispatch({ type: 'START_UPDATE_DETAIL', payload: { status: false, error_message: null } })
        return POST('web/master/metodepembayaran/updateDetail', dataUpdate , authHeader())
            .then(({data}) => {
                const status = data.response.status 
                dispatch({
                    type: 'UPDATE_DETAIL',
                    payload: {
                        status: status,
                    }
                })
            })
            .catch(error => {
                console.log(error)
                const messageErr = handlingError.Response('post update detail metode pembayaran', error, dispatch)
                dispatch({
                    type: 'UPDATE_DETAIL_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}