import React, { useEffect, useState } from 'react';
import { GET, POST } from '../../../config/api';
import authHeader from '../../../config/services/auth-header';
import Illustration from '../../../assets/images/illustration_dashboard.png'
import log from '../../../utils/log';
import Bar from '../../../components/molecules/Chart/Bar'
import moment from 'moment'

function Dashboard(props) {
  const [pasien, setPasien] = useState(0)
  const [dokter, setDokter] = useState(0)
  const [kunjungan, setKunjungan] = useState(0)
  const [konsultasi, setKonsultasi] = useState(0)
  const [faskes, setFaskes] = useState(0)
  const [logFaskesData, setLogFaskesData] = useState([])
  const [logFaskesLabels, setLogFaskesLabels] = useState([])
  const [LogFaskes2, setLogFaskes2] = useState([])
  const [LogFaskesLabel2, setLogFaskesLabel2] = useState([])
  // handle click event of logout button
  useEffect(() => {
        getData();
        getData2()
  },[])

  const getData = () => {
    const body = {}
    try{
        POST('web/dashboard/getData',body,authHeader())
        .then(({data}) => {
            // log('data?', data)
            // return
            const response = data.response 
            const konsultasi = response.konsultasi 
            const kunjungan = response.kunjungan 
            const faskes = response.faskes 
            const pasien = response.pasien 
            const dokter = response.dokter 
            const logfaskes = response.logfaskes
             
            setKonsultasi(konsultasi)
            setKunjungan(kunjungan)
            setFaskes(faskes)
            setPasien(pasien)
            setDokter(dokter)
            extractLogFaskes(logfaskes)
        })
        .catch(error => {
        });
    }catch(error){
        log('error',error);
    }
  }

  const getData2 = () => {
      const date = moment(new Date()).format("YYYY-MM-DD").toString()
    //   console.log(date)
      const body = {"member":0}
      try {
        POST('web/dashboard/getData',body,authHeader())
        .then(({data}) => {
            // console.log('cek', data)
            mapingLogFaseks(data.response.logfaskes)
        })
      } catch (error) {
       log('error', error)   
      }
  }

  const mapingLogFaseks = (data) => {
    //   console.log('data',data)
      if (data != undefined && data.length > 0) {
        var logdata = []
        var loglabels = []
        data.map((item, index) => {
            logdata.push(item.hits)
            loglabels.push(item.nama)
        })
        setLogFaskes2(logdata)
        setLogFaskesLabel2(loglabels)
        // console.log('cek',logFaskesData[0])
    }
  }

  const extractLogFaskes = (data) => {
        if (data != undefined && data.length > 0) {
            var logdata = []
            var loglabels = []
            data.map((item, index) => {
                // console.log(item)
                logdata.push(item.hits)
                loglabels.push(item.nama)
            })
            setLogFaskesData(logdata)
            setLogFaskesLabels(loglabels)
            // console.log('cek',logFaskesData[0])
        }
  }
  
  return (
    <div className="container mx-auto">
        <div className="antialiased font-sans ">
            <div className="container mx-auto px-4 mt-12 shadow overflow-hidden sm:rounded-lg bg-white">
                <div className="py-2">
                    <div className="sm:grid sm:grid-cols-7 gap-1 items-center ">
                      <div className="justify-items-center items-center">
                        <img src={Illustration} alt="" className="object-contain"/></div> 
                      <div className="grid col-span-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Selamat datang!
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                              Dashboard Infokes Mobile 2.0
                          </p>
                          <div className="sm:grid sm:grid-cols items-center py-3">
                            <hr/>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            
            <div className="grid grid-cols-5 gap-2 mt-4 ">
                <div className="shadow overflow-hidden sm:rounded-lg px-4 py-4 bg-white">
                    <div className="grid ">
                        <h3 className="text-md font-medium text-gray-500">
                            Pasien
                        </h3>
                        <p className="text-lg text-black-900 font-bold">
                            {pasien}
                        </p>
                    </div>
                </div>
                <div className="shadow overflow-hidden sm:rounded-lg px-4 py-4 bg-white">
                    <div className="grid">
                        <h3 className="text-md font-medium text-gray-500">
                            Dokter
                        </h3>
                        <p className="text-lg text-black-900 font-bold">
                            {dokter}
                        </p>
                    </div>
                </div>
                {/* <div className="animate-pulse shadow overflow-hidden sm:rounded-lg px-4 py-4 bg-white"> */}
                <div className="shadow overflow-hidden sm:rounded-lg px-4 py-4 bg-white">
                    <div className="grid">
                        <h3 className="text-md font-medium text-gray-500">
                            Faskes
                        </h3>
                        <p className="text-lg text-black-900 font-bold">
                            {faskes}
                        </p>
                    </div>
                </div>
                <div className="shadow overflow-hidden sm:rounded-lg px-4 py-4 bg-white">
                    <div className="grid">
                        <h3 className="text-md font-medium text-gray-500">
                            Konsultasi
                        </h3>
                        <p className="text-lg text-black-900 font-bold">
                            {konsultasi}
                        </p>
                    </div>
                </div>
                <div className="shadow overflow-hidden sm:rounded-lg px-4 py-4 bg-white">
                    <div className="grid">
                        <h3 className="text-md font-medium text-gray-500">
                            Kunjungan
                        </h3>
                        <p className="text-lg text-black-900 font-bold">
                            {kunjungan}
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto px-4 mt-4 shadow overflow-hidden sm:rounded-lg bg-white">
                <div className="py-2">
                    <Bar data={logFaskesData} labels={logFaskesLabels} title="10 Views Terbanyak Faskes Bulan Ini" label="Jumlah Views"/>
                </div>
            </div>
            <div className="container mx-auto px-4 mt-4 shadow overflow-hidden sm:rounded-lg bg-white">
                <div className="py-2">
                    <Bar data={LogFaskes2} labels={LogFaskesLabel2} title="10 Views Terbanyak Non-Member Infokes Bulan Ini" label="Jumlah Views"/>
                    {/* {LogFaskes2.length > 0 
                    ?
                    :
                    null
                    } */}
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default Dashboard;