import { POST } from '../../api';
import authHeader from '../../services/auth-header'
import * as handlingError from './handling'

export const getDetil = (id) => {
    return async dispatch => {
        console.log('id: ', id)
        return POST('web/master/dokter/getDetail', { id: id } , authHeader())
            .then(({data}) => {
                const dataDokter = data.response.dokter 
                const berkas = data.response.berkas
                dispatch({
                    type: 'GET_MASTER_DOKTER_DETAIL',
                    payload: {
                        detaildokter: dataDokter,
                        berkas: berkas
                    }
                })
            })
            .catch(error => {
                console.log(error)
                handlingError.Response('post detil dokter', error, dispatch)
            });
    }
}

export const getFaskesDokter = (id,filterData) => {
    return async dispatch => {
        console.log('id: ', id, 'filterData: ', filterData)
        return POST('web/master/dokter/getFaskesDokter', Object.assign(filterData, { id: id }) , authHeader())
            .then(({data}) => {
                const faskes = data.response.rows 
                const faskesTotal = data.response.totalRows
                dispatch({
                    type: 'GET_FASKES_DOKTER',
                    payload: {
                        dokterfaskes: faskes,
                        dokterfaskestotal: faskesTotal
                    }
                })
            })
            .catch(error => {
                console.log(error)
                handlingError.Response('post faskes dokter', error, dispatch)
            });
    }
}

export const getListFaskes = (query) => {
    return async dispatch => {
        return POST('web/master/dokter/getListFaskes', { query: query } , authHeader())
            .then(({data}) => {
                const faskes = data.response.rows 
                const faskesTotal = data.response.totalRows
                
                var lists = []
                if (faskes !== undefined && faskes.length > 0) {
                    faskes.map((item, i) => {
                        lists[i] = {
                            name: item.nama,
                            value: item.code
                        }
                    })
                }
                
                dispatch({
                    type: 'GET_MASTER_DOKTER_LIST_FASKES',
                    payload: {
                        listfaskes: lists,
                        listfaskestotal: faskesTotal
                    }
                })
            })
            .catch(error => {
                console.log(error)
                handlingError.Response('post list faskes', error, dispatch)
            });
    }
}

export const postAddFaskes = (id,code) => {
    return async dispatch => {
        
        dispatch({
            type: 'START_POST_ADD_FASKES',
            payload: {
                status_add_faskes: false,
                error_message: ''
            }
        })

        return POST('web/master/dokter/addFaskes', { id: id, code: code } , authHeader())
            .then(({data}) => {
                const response = data.response 
                
                dispatch({
                    type: 'POST_ADD_FASKES',
                    payload: {
                        status_add_faskes: response.status
                    }
                })
            })
            .catch(error => {
                console.log(error)
                const messageErr = handlingError.Response('post add faskes', error, dispatch)
                console.log(messageErr)
                dispatch({
                    type: 'POST_ADD_FASKES_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}

export const postDeleteFaskes = (id,code) => {
    return async dispatch => {
        
        dispatch({
            type: 'START_POST_DELETE_FASKES',
            payload: {
                status_delete_faskes: false,
                error_message: ''
            }
        })
        
        return POST('web/master/dokter/deleteFaskes', { id: id, code: code } , authHeader())
            .then(({data}) => {
                const response = data.response 
                
                dispatch({
                    type: 'POST_DELETE_FASKES',
                    payload: {
                        status_delete_faskes: response.status
                    }
                })
            })
            .catch(error => {
                console.log(error)
                const messageErr = handlingError.Response('post delete faskes', error, dispatch)
                console.log(messageErr)
                dispatch({
                    type: 'POST_DELETE_FASKES_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}

export const getTarifDokter = (id,filterData) => {
    return async dispatch => {
        console.log('id: ', id, 'filterData: ', filterData)
        return POST('web/master/dokter/getTarifDokter', Object.assign(filterData, { id: id }) , authHeader())
            .then(({data}) => {
                console.log(data)
                const tarifRow = data.response.rows 
                const tarifTotalRows = data.response.totalRows
                dispatch({
                    type: 'GET_TARIF_DOKTER',
                    payload: {
                        doktertarif: tarifRow,
                        doktertariftotal: tarifTotalRows
                    }
                })
            })
            .catch(error => {
                console.log(error)
                handlingError.Response('post tarif dokter', error, dispatch)
            });
    }
}

export const postDeleteTarif = (id) => {
    return async dispatch => {
        
        dispatch({
            type: 'START_POST_DELETE_TARIF',
            payload: {
                status_delete_tarif: false,
                error_message: ''
            }
        })
        
        console.log(id)

        return POST('web/master/dokter/deleteTarif', { tarif_id: id } , authHeader())
            .then(({data}) => {
                const response = data.response 
                console.log("response: ", response)
                dispatch({
                    type: 'POST_DELETE_TARIF',
                    payload: {
                        status_delete_tarif: response.status
                    }
                })
            })
            .catch(error => {
                console.log(error.response.data)
                const messageErr = handlingError.Response('post delete tarif', error, dispatch)
                console.log(messageErr)
                dispatch({
                    type: 'POST_DELETE_TARIF_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}

export const postAddTarif = (tarifData) => {
    return async dispatch => {
        
        dispatch({
            type: 'START_POST_ADD_TARIF',
            payload: {
                status_add_tarif: false,
                error_message: ''
            }
        })

        return POST('web/master/dokter/addTarif', tarifData , authHeader())
            .then(({data}) => {
                const response = data.response 
                
                dispatch({
                    type: 'POST_ADD_TARIF',
                    payload: {
                        status_add_tarif: response.status
                    }
                })
            })
            .catch(error => {
                console.log(error)
                const messageErr = handlingError.Response('post add tarif', error, dispatch)
                console.log(messageErr)
                dispatch({
                    type: 'POST_ADD_TARIF_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}