import React,{useEffect,useReducer} from 'react';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch(action.type){
        case INPUT_CHANGE:
            return {
                ...state,
                value:action.value
            }
        case INPUT_BLUR:
            return {
                ...state,
                touched:true
            }
        default:
            return state;
    }
}

const InputIcon = props => {
    const [inputState, dispatch] = useReducer(inputReducer,{
        value : props.initialValue ? props.initialValue :'',
        touched : false
    })

    const { onInputChange,id,initialValue } = props;
    // console.log('use effect InputIcon');
    // console.log(id+'=========='+initialValue);
    
    // console.log(id+'=========='+inputState.value);

    useEffect(() => {
        if(inputState.touched){
            onInputChange(id,inputState.value)
        }
    },[inputState,id])

    const textChangeHandler  = (event) => {
        let textInput = event.target.value
        dispatch({type:INPUT_CHANGE,value:textInput})
    }
    const lostFocusHandler = () => {
        dispatch({type:INPUT_BLUR})
    }
    return (
        <div>
            {
                (props.icon==='search') ?
                (
                    <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                            <path
                                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                            </path>
                        </svg>
                    </span>
                )
                : null
            }
            <input placeholder="Search"
                className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" 
                onChange={textChangeHandler}
                onBlur={lostFocusHandler}
                value={inputState.value}
            />
        </div>
    );
}
// InputIcon.propTypes = {
//     initialValue: ''
// };
export default InputIcon;