export const  filterTipe =  ['Harian','Bulanan','Tahunan'];

export const  filterTahun = (startTahun) => {
    var data = [];
    for(var i=startTahun; i>=startTahun-10;i--){
        data.push(i);
    }
    return data;

}

export const  filterBulan =  ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];

export const  filterTgl = (tahun,bulan) => {
    var data = [];
    var lastDay = new Date(tahun, bulan, 0);
    for(var i=1; i<=lastDay.getDate();i++){
        data.push(i);
    }
    return data;

}
export const getLastDate = (tahun,bulan) => {
    var lastDay = new Date(tahun, bulan, 0);
    return lastDay.getDate();

}

export const dataHit = [
    {
        code: 'kurangseratus',
        nama: '< 100'
    },
    {
        code: 'lebihseratus',
        nama: '> 100'
    }
]

export const dataFilter = [
    {
        code: 'nama',
        nama: 'Nama Faskes',
    },
    {
        code: 'hits',
        nama: 'Jumlah di Lihat',
    },
]