
// set the token and user from the session storage
export const changeddmmyyyy = (date) => {
    let dateArray = date.split('-');
    if(dateArray.length === 3){
        return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;

    }
    return date + ' (format must yyyy-mm-dd)';
}

export const  formatMoney = (number) => {
    if (typeof number === 'number') {
        return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return number + ' (not number)';

}