import React  from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const DetilDokter = props => {
    DetilDokter.propTypes = {
        detailDokter: PropTypes.instanceOf(Object).isRequired,
        // berkasDokter: PropTypes.instanceOf(Object).isRequired,
    };
    const detailDokter = props.detailDokter 
    const berkasDokter = props.berkasDokter
    return (
        <div className="grid grid-cols-2 gap-4 mt-10">
            <div className="grid grid-cols-1 gap-1 content-start">
                
                <h2 className="font-semibold text-lg">Informasi Dokter</h2>
                <hr className="mt-2"/>
                <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6">
                    <p className="text-9xl mt-2 text-gray-500">Jenis Kelamin</p>
                    <p className="text-9xl mt-2">
                        {detailDokter !== null ? detailDokter.jenis_kelamin === 'L' ? 'Laki-laki' : 'Perempuan' : null}
                    </p>
                </div>
                <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                    <p className="text-9xl mt-2 text-gray-500">Alamat Sesuai KTP</p>
                    <p className="text-9xl mt-2">
                        {detailDokter !== null ? detailDokter.alamat : null}
                    </p>
                </div>
                <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                    <p className="text-9xl mt-2 text-gray-500">Nomor Telp Dokter (yang akan didaftarkan di aplikasi)</p>
                    <p className="text-9xl mt-2">
                        {detailDokter !== null ? detailDokter.no_hp : null}
                    </p>
                </div>
                <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                    <p className="text-9xl mt-2 text-gray-500">Google Email (yang akan didaftarkan di aplikasi)</p>
                    <p className="text-9xl mt-2">
                        {detailDokter !== null ? detailDokter.email : null}
                    </p>
                </div>
                <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                    <p className="text-9xl mt-2 text-gray-500">Nomor KTP</p>
                    <p className="text-9xl mt-2">
                        {detailDokter !== null ? detailDokter.nik : null}
                    </p>
                </div>
                <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                    <p className="text-9xl mt-2 text-gray-500">Nomor STR (yang masih berlaku)</p>
                    <p className="text-9xl mt-2">
                        {detailDokter !== null ? detailDokter.nomor_str : null}
                    </p>
                </div>
                <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                    <p className="text-9xl mt-2 text-gray-500">Nomor SIP (yang masih berlaku)</p>
                    <p className="text-9xl mt-2">
                        {detailDokter !== null ? detailDokter.nomor_sip : null}
                    </p>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-1 content-start">
                
                <h2 className="font-semibold text-lg">Berkas Scan</h2>
                <hr className="mt-2"/>
                <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6">
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                                
                                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2 flex-1 w-0 truncate">
                                Scan CV
                                </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                                <a href={berkasDokter != null ? berkasDokter.scan_cv : null} className="font-medium text-indigo-600 hover:text-indigo-500">
                                Lihat
                                </a>
                            </div>
                            </li>
                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div className="w-0 flex-1 flex items-center">
                                    
                                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2 flex-1 w-0 truncate">
                                    Scan SIP
                                    </span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                    <a href={berkasDokter != null ? berkasDokter.scan_sip : null} className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Lihat
                                    </a>
                                </div>
                            </li>
                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div className="w-0 flex-1 flex items-center">
                                    
                                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2 flex-1 w-0 truncate">
                                    Scan STR
                                    </span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                    <a href={berkasDokter != null ? berkasDokter.scan_str : null} className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Lihat
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <br/>
                        <span className="sm:ml-3 self-end">
                            <button onClick={() => {
                                props.history.goBack()
                            }} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                Kembali
                            </button>
                        </span>
                    </dd>
                </div>
                <br/>
            </div>
        </div>
    )
}

export default DetilDokter