// import log from '../utils/log';
import {reactLocalStorage} from 'reactjs-localstorage'

// return the user data from the session storage
export const getUser = () => {
    const dt = reactLocalStorage.getObject('dtUser');
    if (dt.user) 
        return dt.user;
    else 
        return null;
}

// return the token from the session storage
export const getToken = () => {
    let dt = reactLocalStorage.getObject('dtUser');
    if(dt.token){
        return dt.token;
    }else{
        return null;
    }
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    reactLocalStorage.remove('dtUser');
    reactLocalStorage.clear();
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
    reactLocalStorage.setObject('dtUser', {'user' : user ,'token' : token});
}