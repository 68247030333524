import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons' 

function Loading(props) {
 
  return (
    <div className="w-full h-full bg-blue-200 fixed flex flex-col justify-center items-center">
        <span className="text-green-500 block relative ">
            <FontAwesomeIcon icon={faSpinner} pulse fixedWidth size="9x" color='white' />
        </span>
    </div>
  );
}
 
export default Loading;
