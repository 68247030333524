import React,{ useEffect,useState,useCallback,useReducer } from 'react';
import authHeader from '../../../config/services/auth-header';
import { GET, POST } from '../../../config/api';
import {useDispatch} from 'react-redux';

import * as handlingError from '../../../config/redux/action/handling';
import Notif from '../../../components/atoms/notif';

import TitlePage from '../../../components/molecules/TitlePage';
import {ManualTable,ManualTHEAD,ManualTBODY,ManualTR,ManualTD,ManualTH,ManualPaging} from '../../../components/molecules/DataTable';
import log from '../../../utils/log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt,faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import SelectInput from '../../../components/atoms/SelectInput';
import InputIcon from '../../../components/atoms/InputIcon';
import Button from '../../../components/atoms/button';
import LoadingLittle from '../LoadingLittle'
import { useHistory } from 'react-router-dom';

const TableBody = (props) => {
    const history = useHistory();
    const handleClick = () => history.push('/faskes/'+props.data.code, { id: props.data.code });

    return (
        <tr onDoubleClick={handleClick}>
            <ManualTD>
                <div className="flex items-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {props.data.code}
                    </p>
                </div>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.nama}</p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.alamat}
                </p>
                <p className="text-gray-900 whitespace-no-wrap">
                    <span
                        className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                        <span aria-hidden
                            className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                        <span className="relative">Tlp. {props.data.notelp} / {props.data.notelp_display}</span>
                    </span>
                </p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.base_url}
                </p>
            </ManualTD>
            <ManualTD>
                <span
                    className="relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight">
                    <span aria-hidden
                        className="absolute inset-0 bg-orange-200 opacity-50 rounded-full"></span>
                    <span className="relative">{props.data.coordinate}</span>
                </span>
            </ManualTD>
            <ManualTD>
                <span
                    className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span aria-hidden
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                    <span className="relative">{props.data.hits != null ? props.data.hits : 0}</span>
                </span>
            </ManualTD>
        </tr>
          
    )
}

const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
const FORM_INPUT_RESET  = 'FORM_INPUT_RESET';

const formReducer = (state,action) => {
    if(action.type === FORM_INPUT_UPDATE){
        const updatedValues = {
            ...state.inputValues,
            [action.input] : action.value
        }
        return {
            inputValues : updatedValues,
        }
    }else if(action.type === FORM_INPUT_RESET){
        const updatedValues = {
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
        }
        return {
            inputValues : updatedValues,
        }
    }
    return state;
}
function Faskes() {
    const [dataContent,setDataContent]  = useState([]);
    const [totalContent,setTotalContent]  = useState(0);
    const [loadData,setLoadData]  = useState(false);
    const [dataDetail,setDataDetail]  = useState(false);
    const [notify,setNotify] 	= useState({show:'hidden'});
    const [classProgress,setClassProgress] 	= useState({
        width : '0%',
        loading : false
    });
    const [stateTransaksiDetail,setStateTransaksiDetail] 	= useState({
        loadData : false,
        id : '',
    });
    const [formFilterState, dispatchFormFilterState] = useReducer(formReducer,{
        inputValues:{
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
        }
    })
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
    },[formFilterState])
    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue) => {
            dispatchFormFilterState({
                type: FORM_INPUT_UPDATE,
                value: inputValue,
                input: inputIdentifier
            });
        },
        [dispatchFormFilterState]
    );
    
    const handleFilterFunc = (e,id) => {
        let filter = e.target.value;
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:filter,
            input:id
        })
    }
    
    const handleMovePage = async(type,id) => {
        if(type==='prev'){
            if(formFilterState.inputValues.pagenum !== 0){
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum-1,
                    input:id
                })
            }
        }else{
            if(((parseInt(formFilterState.inputValues.pagenum+1))*formFilterState.inputValues.pagesize) <= totalContent){
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum+1,
                    input:id
                })
                
            }
        }
    }
    const handleDetailTransaksi = (id) => {
        setStateTransaksiDetail(prevState => ({
            ...prevState,
            loadData : prevState.id!==id && prevState.id!=='' && prevState.loadData ? prevState.loadData : !prevState.loadData,
            id : id,
        }))
    }
    const checkCheck = (data) => {
        setDataDetail(data);
    }
    const handleRefresh = () => {
        dispatchFormFilterState({
            type:FORM_INPUT_RESET,
        });
    }
    const handleSinkron = async() => {
        try{
            let progress = 0;
            let invervalSpeed = 10;
            let incrementSpeed = 1;
            await setClassProgress(prevState => ({
                ...prevState,
                loading : true,
            }));
            let intevalProgress = setInterval(function(){
                progress += incrementSpeed;
                setClassProgress(prevState => ({
                    ...prevState,
                    width : (progress + "%"),
                }));
                if(progress >= 100){
                    setClassProgress(prevState => ({
                        ...prevState,
                        loading : false,
                    }));
                    clearInterval(intevalProgress)
                }
            }, invervalSpeed);   
            await  GET('getFaskesByDate',authHeader())
            .then(result => {
                log('result',result);
                setClassProgress(prevState => ({
                    ...prevState,
                    loading : false,
                }));
                setNotify(	{
                    type:'success',show:'block',title:'Sinkroni Faskes',message:'Sinkronisasi Berhasil'
                });
                setTimeout(() => {
                    setNotify({
                        ...notify
                    });
                }, 3000);
            })
            .catch(error => {
                log('error',error);
                setClassProgress(prevState => ({
                    ...prevState,
                    loading : false,
                }));
                const messageErr =  handlingError.Response('page login - catch login',error,dispatch);
                setNotify(	{
                    type:'danger',show:'block',title:'Sinkroni Faskes',message:messageErr
                });
                setTimeout(() => {
                    setNotify({
                        ...notify
                    });
                }, 3000);
            })
           
        }catch(error){
            setNotify(	{
                type:'danger',show:'block',title:'Data Faskes',message:'Terjadi kesalahan koneksi'
            });
            setTimeout(() => {
                setNotify({
                    ...notify
                });
            }, 3000);
            log('error',error);
        }
    }
    const getData = async() => {
        try{
            log('filterData',formFilterState.inputValues);
            setLoadData(true);
            POST('web/faskes/getFaskes',formFilterState.inputValues,authHeader())
            .then(({data}) => {
                let dataRaw = data.response.rows;
                let totalRaw = data.response.totalRows;
                setDataContent(dataRaw);
                setTotalContent(totalRaw);
                setLoadData(false);
            })
            .catch(error => {
                setLoadData(false);
                setDataContent([]);
                const messageErr =  handlingError.Response('page login - catch login',error,dispatch);
                setNotify(	{
                    type:'danger',show:'block',title:'Data Faskes',message:messageErr
                });
                setTimeout(() => {
                    setNotify({
                        ...notify
                    });
                }, 3000);
            });
        }catch(error){
            log('error',error);
        }
    }
    
  return (
    <div className="container mx-auto">
        <Notif 
                notify = {notify}
            />
        <TitlePage
            title='Faskes'
            data = {['Master Data','Faskes']}
        />
        <div className="antialiased font-sans bg-white">
            <div className="container mx-auto px-4 sm:px-4">
                <div className="py-4 relative">
                    <div className="flex flex-row justify-between items-center">
                        <div>
                            <Button 
                                addclass="bg-green-500 text-white font-bold" 
                                onClick={() => handleRefresh()}
                            >
                                <FontAwesomeIcon icon={faSyncAlt}/> <span className='pl-2'>Refresh</span>
                            </Button>
                            <Button 
                                addclass="bg-blue-500 text-white font-bold" 
                                onClick={() => handleSinkron()}
                            >
                                <FontAwesomeIcon icon={faNetworkWired}/> <span className='pl-2'>Sinkronisasi</span>
                            </Button>
                        </div>
                        <div className="my-2 flex sm:flex-row flex-col">
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput
                                    data={['Semua','Kode','Nama Faskes','Alamat','Koordinate','Tipe Faskes','No Telp']} 
                                    valueTipe = 'value'
                                    value={formFilterState.inputValues.search_key}
                                    onChange={(e) => handleFilterFunc(e,'search_key')}
                                 />
                            </div>
                            <div className="block relative">
                                <InputIcon 
                                    icon='search' 
                                    id='search_value'
                                    onInputChange={inputChangeHandler}
                                    initialValue={formFilterState.inputValues.search_value}
                                />
                            </div>
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['10','20','50','100']}
                                    value={formFilterState.inputValues.pagesize}
                                    onChange={(e) => handleFilterFunc(e,'pagesize')}
                                 />
                            </div>
                        </div>
                    </div>
                    <div className={"relative pt-1 "+(classProgress.loading ? 'visible' : 'invisible')}>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                            <div style={{ width: classProgress.width }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                        </div>
                    </div>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <ManualTable>
                                <ManualTHEAD>
                                    <ManualTR>
                                        <ManualTH>Kode</ManualTH>
                                        <ManualTH>Fakses</ManualTH>
                                        <ManualTH>Alamat</ManualTH>
                                        <ManualTH>URL</ManualTH>
                                        <ManualTH>Koordinat</ManualTH>
                                        <ManualTH>Dilihat</ManualTH>
                                    </ManualTR>
                                </ManualTHEAD>
                                <ManualTBODY>
                                    {
                                        (loadData) ? 
                                        <ManualTR>
                                            <ManualTD>
                                                <LoadingLittle />
                                            </ManualTD>
                                        </ManualTR>    
                                        : 
                                        dataContent.map((element) => {
                                            return (
                                                <TableBody  
                                                    data={element} 
                                                    key={element.id}
                                                    stateTransksi = {stateTransaksiDetail}
                                                    handleState = {(id) => handleDetailTransaksi(id)}
                                                    checkCheck = {(id) => checkCheck(id)}
                                                />
                                            )
                                        })
                                    }
                                </ManualTBODY>
                            </ManualTable>
                            <ManualPaging 
                              pagenum={formFilterState.inputValues.pagenum}  
                              pagesize={formFilterState.inputValues.pagesize}
                              totalContent = {totalContent}
                              handleMovePage={(e) => handleMovePage(e,'pagenum')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default Faskes;