import React from 'react'
import './Menu.css'
import logo from '../../../assets/ic_logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { NavLink } from 'react-router-dom';

const Test = (props) => {
    return (
        <div className="mx-auto flex flex-wrap items-center bg-blue-400 pl-5 ">
        <div className="flex-1 flex justify-between items-center">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <img src={logo} alt="" className="object-contain mr-2" width="50" height="50"/>
                <div className="flex flex-row gap-1">
                    <span className="font-semibold text-xl tracking-tight">Dashboard</span><small>1.0</small>
                    
                </div>
            </div>
        </div>

        <label htmlFor="menu-toggle" className="pointer-cursor md:hidden block pr-6">
            <svg className="fill-current text-gray-300 hover:text-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                viewBox="0 0 20 20">
                <title>menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
        </label>
        <input className="hidden" type="checkbox" id="menu-toggle" />

        <div className="hidden md:flex md:items-center md:w-auto w-full" id="menu">
            <nav>
                <ul className="md:flex items-center justify-between text-base pt-4 md:pt-0">
                    <li>
                        <NavLink className={"md:px-4 py-2 md:py-4 px-0 block text-green-100 hover:text-white md:hover:bg-blue-700"} to="/dashboard">Dashboard</NavLink>
                    </li>
                    <li className="dropdown inline-block relative w-full md:w-auto md:mb-0 mb-2">
                        <a className="md:px-4 py-2 md:py-4 px-0 block text-green-100 hover:text-white md:hover:bg-blue-800 inline-flex items-center">
                            <span className="mr-1">Master Data</span>
                            <FontAwesomeIcon icon={faChevronDown}  size="xs"/>
                        </a>
                        <ul className="dropdown-menu absolute z-50 md:right-0 hidden text-gray-700 md:-mt-4 md:pt-3 pt-1 shadow-lg w-56">
                            <li className="">
                                <NavLink className="rounded-b bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap" to="/pasien">Pasien</NavLink>
                            </li>
                            <li className="">
                                <NavLink className="bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap" to="/dokter">Dokter</NavLink>
                            </li>
                            <li className="">
                                <NavLink className="rounded-b bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap" to="/faskes">Faskes</NavLink>
                            </li>
                            <li className="">
                                <NavLink className="rounded-b bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap" to="/metodepembayaran">Metode Pembayaran</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="dropdown inline-block relative w-full md:w-auto md:mb-0 mb-2">
                        <a className="md:px-4 py-2 md:py-4 px-0 block text-green-100 hover:text-white md:hover:bg-blue-800 inline-flex items-center">
                            <span className="mr-1">Laporan</span>
                            <FontAwesomeIcon icon={faChevronDown}  size="xs"/>
                        </a>
                        <ul className="dropdown-menu absolute z-50 md:right-0 hidden text-gray-700 md:-mt-4 md:pt-3 pt-1 shadow-lg  w-56">
                            <li className="">
                                <NavLink className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap" to="/laporan_konsultasi" >Konsultasi</NavLink>
                            </li>
                            <li className="">
                                <NavLink className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap" to="/log_faskes" >Log Faskes</NavLink>
                            </li>
                            <li className="">
                                <NavLink className="bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap" to="/registrasidokter" >Registrasi Dokter</NavLink>
                            </li>
                        </ul>   
                    </li>
                    <li className="dropdown inline-block relative w-full md:w-auto md:mb-0 mb-2">
                        <a className="md:px-4 py-2 md:py-4 px-0 block text-green-100 hover:text-white md:hover:bg-blue-800 inline-flex items-center">
                            <span className="mr-1">Pengaturan</span>
                            <FontAwesomeIcon icon={faChevronDown}  size="xs"/>
                        </a>
                        <ul className="dropdown-menu absolute z-50 md:right-0 hidden text-gray-700 md:-mt-4 md:pt-3 pt-1 shadow-lg  w-56">
                            <li className="">
                                <NavLink className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap" to="/konfigurasi" >Konfigurasi</NavLink>
                            </li>
                        </ul>   
                    </li>
                    <li><span className="md:px-4 py-2 md:py-4 px-0 block text-green-100 hover:text-white md:hover:bg-blue-800 inline-flex items-center" onClick={props.handleLogout}>Logout</span></li>
                </ul>
            </nav>
        </div>
    </div>
    
    
    )
}   
export default Test