import React,{useEffect, useState} from 'react'
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GET } from '../../../../../config/api';
import authHeader from '../../../../../config/services/auth-header';
import * as handlingError from '../../../../../config/redux/action/handling';
import log from '../../../../../utils/log';
import {changeddmmyyyy} from '../../../../../utils/formatDate'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Notif from '../../../../../components/atoms/notif';
import TitlePage from '../../../../../components/molecules/TitlePage';
import Button from '../../../../../components/atoms/button';


const PasienDetail = props => {
    const id = props.location.state.id
    const history = useHistory();
    const [notify,setNotify] 	= useState({show:'hidden'});
    const [dataContent,setDataContent] 	= useState([]);
    const [loadData,setLoadData]  = useState(false);

    const dispatch = useDispatch();
    const getData = async() => {
        try{
            setLoadData(true);
            GET('web/pasien/getPasienDetail?id='+id,authHeader())
            .then(({data}) => {
                let dataRaw = data.response;
                log('dataRaw',dataRaw);
                setDataContent(dataRaw);
                setLoadData(false);
            })
            .catch(error => {
                setLoadData(false);
                setDataContent([]);
                log('error',error);
                const messageErr =  handlingError.Response('page login - catch login',error,dispatch);
                setNotify(	{
                    type:'danger',show:'block',title:'Data Faskes',message:messageErr
                });
                setTimeout(() => {
                    setNotify({
                        ...notify
                    });
                }, 3000);
            });
        }catch(error){
            log('error',error);
        }
    }
    useEffect(() => {
        getData();
    },[id])
    return (
        <div className="container mx-auto">
            <Notif 
                notify = {notify}
            />
            <TitlePage
                title='Pasien Detail'
                data = {['Master Data','Pasien Detail']}
            />
                <Button 
                    addclass="bg-green-500 text-white font-bold" 
                    onClick={() => history.goBack()}
                >
                    <FontAwesomeIcon icon={faArrowLeft}/> <span className='pl-2'>Kembali</span>
                </Button>
                <div className="flex flex-row justify-between">
                    <div className="flex-1 pr-2">
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">User ID</p>
                            <p className="text-9xl mt-2">
                                {dataContent.user_id ? dataContent.user_id : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Username</p>
                            <p className="text-9xl mt-2">
                                {dataContent.username ? dataContent.username : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Email</p>
                            <p className="text-9xl mt-2">
                                {dataContent.email ? dataContent.email : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Status</p>
                            <p className="text-9xl mt-2">
                            <span className={"px-1 py-1 rounded-sm "+(dataContent.email_verified_at ? 'bg-green-300' : 'bg-red-300')}>
                                {dataContent.email_verified_at ? 'Verifikasi pada '+dataContent.email_verified_at : 'Belum Verifikasi'} 
                            </span>
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Asuransi</p>
                                <span className="text-9xl mt-2 mr-6">
                                {
                                dataContent.dt_asuransi && dataContent.dt_asuransi.length > 0  ?
                                 ( 
                                <table className="border-collapse w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">No</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Nama Asuransi</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">No Asuransi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataContent.dt_asuransi.map((element,i) => {
                                                return (
                                                  
                                                    <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 cursor-pointer"  key={i} >
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            {i+1}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 border border-b block lg:table-cell relative lg:static">
                                                            {element.nama_asuransi.toUpperCase()}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            {element.value}
                                                        </td>
                                                    </tr>
                                                  
                                                )
                                            })
                                        }
                                      </tbody>
                                </table>
                                )  : '-'
                                }
                            </span>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">No Rekam Medis</p>
                                <span className="text-9xl mt-2 mr-6">
                                {
                                dataContent.dt_rekamMedis && dataContent.dt_rekamMedis.length > 0  ?
                                 ( 
                                <table className="border-collapse w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">No</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Nama Faskes</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">No RM</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataContent.dt_rekamMedis.map((element,i) => {
                                                return (
                                                  
                                                    <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 cursor-pointer"  key={i} >
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            {i+1}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-left border border-b block lg:table-cell relative lg:static">
                                                            {element.nama_faskes.toUpperCase()}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            {element.no_rm}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            <span className={"px-1 py-1 rounded-sm "+(element.status==='1' ? 'bg-green-300' : 'bg-red-300')}>
                                                                {element.status==='1' ? 'AKTIP' : 'TIDAK AKTIP'}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                  
                                                )
                                            })
                                        }
                                      </tbody>
                                </table>
                                )  : '-'
                                }
                            </span>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Data Anggota</p>
                                <span className="text-9xl mt-2 mr-6">
                                {
                                dataContent.dt_anggota && dataContent.dt_anggota.length > 0  ?
                                 ( 
                                <table className="border-collapse w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">No</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Nama</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">No KK</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Hubungan</th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataContent.dt_anggota.map((element,i) => {
                                                const handleClick = () => history.push('/pasien/'+element.user_id, { id: element.user_id });
                                                return (
                                                  
                                                    <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 cursor-pointer"  key={element.id} onDoubleClick={handleClick}>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            {i+1}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 border border-b block lg:table-cell relative lg:static">
                                                            {element.nama_lengkap.toUpperCase()}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            {element.no_kk}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 border border-b block lg:table-cell relative lg:static">
                                                            {element.hubungan.toUpperCase()}
                                                        </td>
                                                        <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            <span className={"px-1 py-1 rounded-sm "+(element.status==='1' ? 'bg-green-300' : 'bg-red-300')}>
                                                            {element.status==='1' ? 'AKTIP' : 'TIDAK AKTIP'}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                  
                                                )
                                            })
                                        }
                                      </tbody>
                                </table>
                                )  : '-'
                                }
                            </span>
                        </div>
                    </div>
                    <div  className="flex-1 pl-2">
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Nama</p>
                            <p className="text-9xl mt-2">
                                {dataContent.nama_lengkap ? dataContent.nama_lengkap : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">No KK</p>
                            <p className="text-9xl mt-2">
                                {dataContent.no_kk ? dataContent.no_kk : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">NIK</p>
                            <p className="text-9xl mt-2">
                                {dataContent.nik ? dataContent.nik : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Jenis Kelamin</p>
                            <p className="text-9xl mt-2">
                                {dataContent.jenis_kelamin ? (dataContent.jenis_kelamin=='P' ? 'Perempuan' :'Laki-laki')  : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Tempat, Tanggal Lahir</p>
                            <p className="text-9xl mt-2">
                                {dataContent.tempat_lahir ? dataContent.tempat_lahir : '-'}, {dataContent.tanggal_lahir ? changeddmmyyyy(dataContent.tanggal_lahir) : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Alamat</p>
                            <p className="text-9xl mt-2">
                                {dataContent.alamat ? dataContent.alamat : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">No HP</p>
                            <p className="text-9xl mt-2">
                                {dataContent.no_hp ? dataContent.no_hp : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Berat Badan</p>
                            <p className="text-9xl mt-2">
                                {dataContent.bb ? dataContent.bb : '-'}
                            </p>
                        </div>
                        <div className="flex flex-col shadow bg-white mt-3 rounded-md pl-6 pt-3 pb-3">
                            <p className="text-9xl mt-2 text-gray-500">Tinggi Badan</p>
                            <p className="text-9xl mt-2">
                                {dataContent.tb ? dataContent.tb : '-'}
                            </p>
                        </div>
                    </div>
                </div>
  
        </div>
    );
}
export default PasienDetail;