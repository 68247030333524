import React,{ useEffect,useState } from 'react';
// import authHeader from '../../../../../config/services/auth-header';
// import { POST } from '../../../../../config/api';
import Notif from '../../../../../components/atoms/notif';
import TitlePage from '../../../../../components/molecules/TitlePage';
// import log from '../../../../../utils/log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';
import Modal from '../../../../../components/molecules/Modal'
import  * as RegistrasiDokterActions from '../../../../../config/redux/action/registrasidokter'
import { useStore, useDispatch } from 'react-redux'

const RegistrasiDokterDetail = props => {
    
    const [detailDokter, setDetailDokter] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [universitas, setUniversitas] = useState("")
    const [tahunlulus, setTahunLulus] = useState("")
    const [jurusan, setJurusan] = useState("")
    const [mulaiPraktek, setMulaiPraktek] = useState("")
    const [spesialis, setSpesialis] = useState("")
    const [notify,setNotify] 	= useState({show:'hidden'});
    const dispatch = useDispatch()
    const history = useHistory();
    const store = useStore()

    useEffect(() => {
        loadDetail()
    },[])
    
    const loadDetail = async () => {
        const id = props.location.state.id
        await dispatch(RegistrasiDokterActions.getDetil(id))
        const data = store.getState().registrasidokter.detaildokter
        const isactive = store.getState().registrasidokter.isactive
        console.log(isactive)
        setDetailDokter(data)
        setIsActive(isactive)
    }

    const activateAccount = async () => {
        const id = props.location.state.id
        const data = {
            id: id,
            universitas: universitas,
            tahun_lulus: tahunlulus,
            jurusan: jurusan,
            mulai_praktek: mulaiPraktek,
            spesialis_id: spesialis
        }

        await dispatch(RegistrasiDokterActions.postActivateAccount(data))
        const error_message = store.getState().registrasidokter.error_message
        
        if (error_message) {
            const notif = {
                type:'danger',show:'block',title:'Registrasi Dokter',message:error_message
            }
            setNotify(notif)
            setShowModal(false)

            setTimeout(() => {
                setNotify({...notify})
            }, 2000);
        }

        const status = store.getState().registrasidokter.status 
        const userid = store.getState().registrasidokter.id
        if (status) {
            setShowModal(false)
            const message = ["Data berhasil disimpan"]
            const notif = {
                type:'success',show:'block',title:'Registrasi Dokter',message:message
            }
            setNotify(notif)
            setTimeout(() => {
                setNotify({...notify})
                props.history.push('/dokter/' + userid, { id: userid });
            }, 2000);
        }
    }
    
    // setDetailDokter(store.getState().registrasidokter)
    return (
    <div className="container mx-auto">
        <Notif 
				notify = {notify}
			/>
        <TitlePage
            title='Registrasi Dokter Detail'
            data = {['Laporan','Registrasi Dokter Detail']}
        />
        <Modal show={showModal} title={"Aktivasi akun"} body={"Apakah Anda yakin akan mengaktivasi akun ini?"} onClose={() => {
            setShowModal(!showModal)
        }} btnCancelText={"Batal"} btnOkText={"Ya, Aktifkan"} onSubmit={() => activateAccount()}/>
        <div className="antialiased font-sans bg-gray">
            <div className="container mx-auto px-4 sm:px-8">
                <div className="py-8">
                    <div className="flex flex-col justify-center object-center align-middle justify-items-center mt-10">
                        <img className="rounded-full border-gray-100 w-48 border-2 self-center" src={detailDokter.foto !== undefined ? detailDokter.foto : null} alt=""/>
                        <p className="text-9xl mt-2 self-center" style={{ fontSize: 36, fontWeight: 'bold' }}>{detailDokter.namalengkap !== undefined ? detailDokter.namalengkap : null}</p>
                        <p className="text-gray-500 self-center">{detailDokter.tempatlahir !== undefined ? detailDokter.tempatlahir : null}, {detailDokter.tanggallahir !== undefined ? detailDokter.tanggallahir : null}</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-10">
                        <div className="grid grid-cols-1 gap-1 content-start">
                            
                            <h2 className="font-semibold text-lg">Informasi Dokter</h2>
                            <hr className="mt-2"/>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6">
                                <p className="text-9xl mt-2 text-gray-500">Jenis Kelamin</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.jeniskelamin !== undefined ? detailDokter.jeniskelamin === 'L' ? 'Laki-laki' : 'Perempuan' : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Alamat Sesuai KTP</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.alamat !== undefined ? detailDokter.alamat : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Nomor Telp Dokter (yang akan didaftarkan di aplikasi)</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.nomorhp !== undefined ? detailDokter.nomorhp : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Google Email (yang akan didaftarkan di aplikasi)</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.email !== undefined ? detailDokter.email : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Nomor KTP</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.nomorktp !== undefined ? detailDokter.nomorktp : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Nomor STR (yang masih berlaku)</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.nomorstr !== undefined ? detailDokter.nomorstr : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Nomor SIP (yang masih berlaku)</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.nomorsip !== undefined ? detailDokter.nomorsip : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Alamat SIP (Sesuai lokasi praktek yang terdaftar di Google Maps)</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.alamatsip !== undefined ? detailDokter.alamatsip : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Instansi Kerja Saat Ini dan Lamanya Waktu Kerja di Instansi yang Saat ini Dijalani.</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.instansi_kerja !== undefined ? detailDokter.instansi_kerja : null}
                                </p>
                            </div>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6 ">
                                <p className="text-9xl mt-2 text-gray-500">Pendidikan yang telah dijalani (Nama Universitas, Angkatan)</p>
                                <p className="text-9xl mt-2">
                                    {detailDokter.pendidikan !== undefined ? detailDokter.pendidikan : null}
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-1 content-start">
                            
                            <h2 className="font-semibold text-lg">Berkas Scan</h2>
                            <hr className="mt-2"/>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            
                                            <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                                            </svg>
                                            <span className="ml-2 flex-1 w-0 truncate">
                                            Scan CV
                                            </span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <a href={detailDokter.scan_cv !== undefined ? detailDokter.scan_cv : null} className="font-medium text-indigo-600 hover:text-indigo-500">
                                            Lihat
                                            </a>
                                        </div>
                                        </li>
                                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div className="w-0 flex-1 flex items-center">
                                                
                                                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                                                </svg>
                                                <span className="ml-2 flex-1 w-0 truncate">
                                                Scan SIP
                                                </span>
                                            </div>
                                            <div className="ml-4 flex-shrink-0">
                                                <a href={detailDokter.scan_sip !== undefined ? detailDokter.scan_sip : null} className="font-medium text-indigo-600 hover:text-indigo-500">
                                                Lihat
                                                </a>
                                            </div>
                                        </li>
                                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div className="w-0 flex-1 flex items-center">
                                                
                                                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                                                </svg>
                                                <span className="ml-2 flex-1 w-0 truncate">
                                                Scan STR
                                                </span>
                                            </div>
                                            <div className="ml-4 flex-shrink-0">
                                                <a href={detailDokter.scan_str !== undefined ? detailDokter.scan_str : null} className="font-medium text-indigo-600 hover:text-indigo-500">
                                                Lihat
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </dd>
                                
                            </div>
                            <br/>
                            <h2 className="font-semibold text-lg">Aktivasi Akun</h2>
                            <hr className="mt-2"/>
                            <div className="flex flex-col shadow bg-white mt-5 rounded-md p-6">
                                {!isActive ?
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div className="rounded-md divide-y divide-gray-200">
                                        <p className="text-gray-800">Silahkan masukan detil-detil berikut:</p>
                                        <div className="flex flex-col mb-4">
                                            <p className="text-9xl mt-2 text-gray-700">Universitas</p>
                                            <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: Universitas Indonesia"
                                            onChange={(event) => {
                                                setUniversitas(event.target.value)
                                            }} value={universitas}/>
                                        </div>
                                        <div className="flex flex-col mb-4">
                                            <p className="text-9xl mt-2 text-gray-700">Jurusan</p>
                                            <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: Kedokteran"
                                            onChange={(event) => {
                                                setJurusan(event.target.value)
                                            }} value={jurusan}/>
                                        </div>
                                        <div className="flex flex-col mb-4">
                                            <p className="text-9xl mt-2 text-gray-700">Tahun Lulus</p>
                                            <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: 2010"
                                            onChange={(event) => {
                                                setTahunLulus(event.target.value)
                                            }} value={tahunlulus}/>
                                        </div>
                                        <div className="flex flex-col mb-4">
                                            <p className="text-9xl mt-2 text-gray-700">Mulai Praktek</p>
                                            <input type="text" className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2" placeholder="cth: 2013"
                                            onChange={(event) => {
                                                setMulaiPraktek(event.target.value)
                                            }} value={mulaiPraktek}/>
                                        </div>
                                        <div className="flex flex-col mb-4">
                                            <p className="text-9xl mt-2 text-gray-700">Pilih Spesialis</p>
                                            <select className="mt-2 px-4 py-3 rounded-full border-gray-400 border-2"  name="spesialis_id" id="spesialis_id"
                                            onChange={(event) => {
                                                setSpesialis(event.target.value)
                                            }}>
                                                <option value="">-- Pilih Spesialis --</option>
                                                <option value="umum">Umum</option>
                                            </select>
                                            <small className="mt-2 text-gray-700">Jika tidak ada pilihan spesialis, silahkan daftarkan spesialis terlebih dahulu.</small>
                                        </div>
                                        <span className="sm:ml-3 self-end">
                                            <button onClick={() => {
                                                setShowModal(!showModal)
                                            }} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                                <FontAwesomeIcon icon={faCheck} className="mr-2"/>
                                                Aktivasi Akun
                                            </button>
                                        </span>
                                        <span className="sm:ml-3 self-end">
                                            <button  onClick={() => {
                                                history.goBack()
                                            }} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>Kembali
                                            </button>
                                        </span>
                                    </div>
                                </dd> : 
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <p className="text-9xl mt-2 text-gray-700 mb-4">Status Akun</p>
                                <span className="px-2 inline-flex text-lg py-4 leading-5 font-semibold rounded-lg bg-green-200 text-green-800">
                                    <FontAwesomeIcon icon={faCheck} className="mr-2"/>Akun sudah aktif
                                </span></dd>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default RegistrasiDokterDetail;