import authHeader from './auth-header';
import {POST} from '../api';
// import log from '../../utils/log';

const Auth = (username, password) => {
    return new Promise(function(resolve, reject){
        let body = {
            'username' : username,
            'password' : password,
            'sumber_login'   : 'infokes',
        }
        POST('getTokenLuar',body,{})
        .then(result => {
            resolve(result);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const CheckAuth = () => {
    return new Promise(function(resolve, reject){
        if(authHeader().username && authHeader().token){
            POST('checkTokenLuar',{},authHeader())
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        }else{
            reject('username & token tidak valid')
        }
    });
}

export {
    Auth,
    CheckAuth
}