import React from 'react';
import './Loading.css'
function LoadingLittle(props) {
 
  return (
      <span className="w-full h-200 fixed bg-white flex flex-col justify-center items-center">
        <div className="top-0 left-0 z-50 w-screen h-24 flex items-center justify-center bg-gray-100" >
          <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">
            <div className="loader-dots block relative w-20 h-5 mt-2">
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
            </div>
            <div className="text-gray-500 text-xs font-light mt-2 text-center">
              Tunggu Sebentar...
            </div>
          </div>
        </div>
        </span>
  );
}
 
export default LoadingLittle;
