import { POST } from '../../../config/api';
import authHeader from '../../../config/services/auth-header'
import * as handlingError from './handling'

export const getDetil = (id) => {
    return async dispatch => {
        console.log('id: ', id)
        return POST('web/healthcare/getDetail', { id: id } ,authHeader())
            .then(({data}) => {
                const dataDokter = data.response.dokter 
                const isActive = data.response.is_active
                dispatch({
                    type: 'GET_DETIL_DOKTER',
                    payload: {
                        detaildokter: dataDokter,
                        isactive: isActive
                    }
                })
            })
            .catch(error => {
                console.log(error)
                handlingError.Response('post detil registrasi dokter', error, dispatch)
            });
    }
}

export const postActivateAccount = (data) => {
    return async dispatch => {
        console.log('data: ', data)
        return POST('web/healthcare/activateAccount', data ,authHeader())
            .then(({data}) => {
                const response = data.response 
                dispatch({
                    type: 'POST_ACTIVATE_ACCOUNT',
                    payload: {
                        status: response.status,
                        id: response.user_id
                    }
                })
            })
            .catch(error => {
                console.log(error.response.data)
                const messageErr = handlingError.Response('post account activation', error)
                dispatch({
                    type: 'POST_ACTIVATE_ACCOUNT_FAILED',
                    payload: {
                        error_message: messageErr
                    }
                })
            });
    }
}