import React from 'react';
import {createStore, combineReducers,applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import ReduxThunk from 'redux-thunk';
import authReducer from './config/redux/reducer/auth';
import registrasiDokterReducer from './config/redux/reducer/registrasidokter'
import dokterReducer from './config/redux/reducer/dokter'
import konfigurasiReducer from './config/redux/reducer/konfigurasi'
import metodeBayarReducer from './config/redux/reducer/metodepembayaran'
import filterMasterReducer from './config/redux/reducer/filterMaster';

import Apps from './containers/templates/app/index';

const rootReducer = combineReducers({
	auth 	: authReducer,
	registrasidokter: registrasiDokterReducer,
	dokter : dokterReducer,
	konfigurasi: konfigurasiReducer,
	metodepembayaran: metodeBayarReducer,
	filterMaster: filterMasterReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {
  return (
  	<Provider store={store} >
  		<Apps />
	</Provider>
  );
}

export default App;
