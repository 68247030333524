import {app_type} from '../env';
export default (title,message) => {
    if(app_type==='development'){
        console.log('=============='+title+'===============');
        if(Array.isArray(message)){
            message.forEach(element => {
                console.log(element); 
            });
        }else{
            console.log(message); 
        }
    }
}

