import React,{ useEffect,useState,useCallback,useReducer } from 'react';
import authHeader from '../../../../config/services/auth-header';
import { POST } from '../../../../config/api';
import {useDispatch} from 'react-redux';

import * as handlingError from '../../../../config/redux/action/handling';
import Notif from '../../../../components/atoms/notif';

import TitlePage from '../../../../components/molecules/TitlePage';
import {ManualTable,ManualTHEAD,ManualTBODY,ManualTR,ManualTD,ManualTH,ManualPaging} from '../../../../components/molecules/DataTable';
import log from '../../../../utils/log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import SelectInput from '../../../../components/atoms/SelectInput';
import InputIcon from '../../../../components/atoms/InputIcon';
import Button from '../../../../components/atoms/button';
import LoadingLittle from '../../LoadingLittle'
import { useHistory } from 'react-router-dom';

const TableBody = (props) => {
    const history = useHistory();
    const handleClick = () => history.push('/pasien/'+props.data.user_id, { id: props.data.user_id });

    return (
        <tr onDoubleClick={handleClick}>
            <ManualTD>
                <div className="flex items-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {props.data.user_id}
                    </p>
                </div>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.nama_lengkap}
                </p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.email} /
                </p>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.no_hp}
                </p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.alamat}
                </p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.no_kk}
                </p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap"> 
                    <span className={"px-1 py-1 rounded-sm "+(props.data.email_verified_at ? 'bg-green-300' : 'bg-red-300')}>{props.data.email_verified_at ? 'Verifikasi' : 'Belum Verifikasi'} </span>
                </p>
            </ManualTD>
        </tr>
          
    )
}

const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
const FORM_INPUT_RESET  = 'FORM_INPUT_RESET';

const formReducer = (state,action) => {
    if(action.type === FORM_INPUT_UPDATE){
        const updatedValues = {
            ...state.inputValues,
            [action.input] : action.value
        }
        return {
            inputValues : updatedValues,
        }
    }else if(action.type === FORM_INPUT_RESET){
        const updatedValues = {
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
        }
        return {
            inputValues : updatedValues,
        }
    }
    return state;
}
function Pasien() {
    const [dataContent,setDataContent]  = useState([]);
    const [totalContent,setTotalContent]  = useState(0);
    const [loadData,setLoadData]  = useState(false);
    const [dataDetail,setDataDetail]  = useState(false);
    const [notify,setNotify] 	= useState({show:'hidden'});
   
    const [stateTransaksiDetail,setStateTransaksiDetail] 	= useState({
        loadData : false,
        id : '',
    });
    const [formFilterState, dispatchFormFilterState] = useReducer(formReducer,{
        inputValues:{
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
        }
    })
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
    },[formFilterState])
    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue) => {
            dispatchFormFilterState({
                type: FORM_INPUT_UPDATE,
                value: inputValue,
                input: inputIdentifier
            });
        },
        [dispatchFormFilterState]
    );
    
    const handleFilterFunc = (e,id) => {
        let filter = e.target.value;
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:filter,
            input:id
        })
    }
    
    const handleMovePage = async(type,id) => {
        if(type==='prev'){
            if(formFilterState.inputValues.pagenum !== 0){
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum-1,
                    input:id
                })
            }
        }else{
            if(((parseInt(formFilterState.inputValues.pagenum+1))*formFilterState.inputValues.pagesize) <= totalContent){
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum+1,
                    input:id
                })
                
            }
        }
    }
    const handleDetailTransaksi = (id) => {
        setStateTransaksiDetail(prevState => ({
            ...prevState,
            loadData : prevState.id!==id && prevState.id!=='' && prevState.loadData ? prevState.loadData : !prevState.loadData,
            id : id,
        }))
    }
    const checkCheck = (data) => {
        setDataDetail(data);
    }
    const handleRefresh = () => {
        dispatchFormFilterState({
            type:FORM_INPUT_RESET,
        });
    }
    
    const getData = async() => {
        try{
            log('filterData',formFilterState.inputValues);
            setLoadData(true);
            POST('web/pasien/getPasien',formFilterState.inputValues,authHeader())
            .then(({data}) => {
                let dataRaw = data.response.rows;
                let totalRaw = data.response.totalRows;
                setDataContent(dataRaw);
                setTotalContent(totalRaw);
                setLoadData(false);
            })
            .catch(error => {
                setLoadData(false);
                setDataContent([]);
                const messageErr =  handlingError.Response('page login - catch login',error,dispatch);
                setNotify(	{
                    type:'danger',show:'block',title:'Data Faskes',message:messageErr
                });
                setTimeout(() => {
                    setNotify({
                        ...notify
                    });
                }, 3000);
            });
        }catch(error){
            log('error',error);
        }
    }
  return (
    <div className="container mx-auto">
        <Notif 
                notify = {notify}
            />
        <TitlePage
            title='Pasien'
            data = {['Master Data','Pasien']}
        />
        <div className="antialiased font-sans bg-white">
            <div className="container mx-auto px-4 sm:px-4">
                <div className="py-4 relative">
                    <div className="flex flex-row justify-between items-center">
                        <div>
                            <Button 
                                addclass="bg-green-500 text-white font-bold" 
                                onClick={() => handleRefresh()}
                            >
                                <FontAwesomeIcon icon={faSyncAlt}/> <span className='pl-2'>Refresh</span>
                            </Button>
                        </div>
                        <div className="my-2 flex sm:flex-row flex-col">
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput
                                    data={['Semua','Nama','User ID','No HP','Email','Alamat','No KK']} 
                                    valueTipe = 'value'
                                    value={formFilterState.inputValues.search_key}
                                    onChange={(e) => handleFilterFunc(e,'search_key')}
                                 />
                            </div>
                            <div className="block relative">
                                <InputIcon 
                                    icon='search' 
                                    id='search_value'
                                    onInputChange={inputChangeHandler}
                                    initialValue={formFilterState.inputValues.search_value}
                                />
                            </div>
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['10','20','50','100']}
                                    value={formFilterState.inputValues.pagesize}
                                    onChange={(e) => handleFilterFunc(e,'pagesize')}
                                 />
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <ManualTable>
                                <ManualTHEAD>
                                    <ManualTR>
                                        <ManualTH>ID</ManualTH>
                                        <ManualTH>Nama Pasien</ManualTH>
                                        <ManualTH>Email / No HP</ManualTH>
                                        <ManualTH>Alamat</ManualTH>
                                        <ManualTH>No KK</ManualTH>
                                        <ManualTH>Status</ManualTH>
                                    </ManualTR>
                                </ManualTHEAD>
                                <ManualTBODY>
                                    {
                                        (loadData) ? 
                                        <ManualTR>
                                            <ManualTD>
                                                <LoadingLittle />
                                            </ManualTD>
                                        </ManualTR>    
                                        : 
                                        dataContent.map((element) => {
                                            return (
                                                <TableBody  
                                                    data={element} 
                                                    key={element.id}
                                                    stateTransksi = {stateTransaksiDetail}
                                                    handleState = {(id) => handleDetailTransaksi(id)}
                                                    checkCheck = {(id) => checkCheck(id)}
                                                />
                                            )
                                        })
                                    }
                                </ManualTBODY>
                            </ManualTable>
                            <ManualPaging 
                              pagenum={formFilterState.inputValues.pagenum}  
                              pagesize={formFilterState.inputValues.pagesize}
                              totalContent = {totalContent}
                              handleMovePage={(e) => handleMovePage(e,'pagenum')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default Pasien;