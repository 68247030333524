import React from 'react';


const SelectFilter = props => {
    const dataOption = () => {
        let render = []
        props.data.map((key,i) => {
            if(props.id == 'filter_member'){
                render.push(
                    <option key={i} value={key.code} >{key.nama} infokes</option>
                )    
            } else {
                render.push(
                    <option key={i} value={key.code} >{key.nama}</option>
                )
            }
        })
        return render
    }
    return (
        <div className="relative">
            <select
                className={"h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-1 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "+props.addClass+""}
                onChange={(e) => props.onChange(e)}
                value = {props.value}
            >
                <option value=''>None</option>
                {dataOption()}
            </select>
        </div>
    );
}
SelectFilter.defaultProps = {
    addClass : '',
    valueTipe : 'value',
    data : []
}
export default SelectFilter;