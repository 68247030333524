import React,{ useEffect,useState,useCallback,useReducer } from 'react';
import authHeader from '../../../../config/services/auth-header';
import { POST } from '../../../../config/api';
import {useDispatch} from 'react-redux';

import * as handlingError from '../../../../config/redux/action/handling';
import Notif from '../../../../components/atoms/notif';
import {changeddmmyyyy,formatMoney} from '../../../../utils/formatDate'
import {filterTipe,filterTahun,filterBulan,filterTgl,getLastDate} from '../../../../utils/filterData'

import TitlePage from '../../../../components/molecules/TitlePage';
import {ManualTable,ManualTHEAD,ManualTBODY,ManualTR,ManualTD,ManualTH,ManualPaging} from '../../../../components/molecules/DataTable';
import log from '../../../../utils/log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import SelectInput from '../../../../components/atoms/SelectInput';
import InputIcon from '../../../../components/atoms/InputIcon';
import Button from '../../../../components/atoms/button';
import LoadingLittle from '../../LoadingLittle'

const TableBody = (props) => {
    
    return (
        <tr onDoubleClick={(data) => props.checkCheck(props.data)}>
            <ManualTD>
                <div className="flex items-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {props.data.id}
                    </p>
                </div>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.nama_faskes}</p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.nama_pasien}
                </p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">
                    {props.data.nama_dokter}
                </p>
            </ManualTD>
            <ManualTD>
                <span className="text-gray-900 whitespace-no-wrap">
                    <div>
                    Tanggal : {changeddmmyyyy(props.data.tanggal_konsultasi)}
                    </div>
                    <div>
                    Paket : {props.data.paket_menit} Menit
                    </div>
                    <div>
                    Total : <span className="text-blue-500 underline cursor-pointer" onClick={(id) => props.handleState(props.data.id)}>
                        {formatMoney(parseFloat(props.data.tarif_total))}</span>
                        <div className={"flex flex-col pl-3 "+(props.stateTransksi.loadData && props.data.id===props.stateTransksi.id ? 'show' :'hidden')} >
                            <div>Metode Pembayaran : {props.data.nama_metode_bayar}</div> 
                            <div>Administrasi : {formatMoney(parseFloat(props.data.tarif_adm))}</div>
                            <div>Tarif : {formatMoney(parseFloat(props.data.tarif))}</div> 
                        </div>
                    </div>
                    {
                        (props.data.asuransi) ? 
                            (
                                <div>
                                Asuransi : {props.data.asuransi} / {props.data.no_asuransi}
                                </div>
                            )
                        : null
                    }
                </span>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap"> 
                    <span className={"px-1 py-1 rounded-sm "+(props.data.status==='belum' ? 'bg-red-300' : (props.data.status==='sudah' ? 'bg-green-300' : 'bg-white'))}>{props.data.status} </span>
                </p>
            </ManualTD>
        </tr>
          
    )
}

const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
const FORM_INPUT_RESET  = 'FORM_INPUT_RESET';

const formReducer = (state,action) => {
    if(action.type === FORM_INPUT_UPDATE){
        const updatedValues = {
            ...state.inputValues,
            [action.input] : action.value
        }
        return {
            inputValues : updatedValues,
        }
    }else if(action.type === FORM_INPUT_RESET){
        const updatedValues = {
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
            filter_tipe     : 'harian',
            filter_tahun    : new Date().getFullYear(),
            filter_bulana   : 1,
            filter_bulanb   : new Date().getMonth() + 1,
            filter_tgla     : 1,
            filter_tglb     : getLastDate(new Date().getFullYear(),new Date().getMonth() + 1)
        }
        return {
            inputValues : updatedValues,
        }
    }
    return state;
}
function LaporanKonsultasi() {
    const [dataContent,setDataContent]  = useState([]);
    const [totalContent,setTotalContent]  = useState(0);
    const [loadData,setLoadData]  = useState(false);
    const [notify,setNotify] 	= useState({show:'hidden'});
    const [showModal,setShowModal] 	= useState(false);
    const [dataDetail,setDataDetail] 	= useState([]);
    const [nilaiFilter,setNilaiFilter] 	= useState({
        totalBelum : 0,
        nilaiBelum : 0,
        totalSudah : 0,
        nilaiSudah : 0,
    });
    const [stateTransaksiDetail,setStateTransaksiDetail] 	= useState({
        loadData : false,
        id : '',
    });
    const [actionFilterTipe,setActionFilterTipe] = useState({
        harian : true,
        bulanan : false,
        tahunan : false,

    });  
    const [formFilterState, dispatchFormFilterState] = useReducer(formReducer,{
        inputValues:{
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
            filter_tipe     : 'harian',
            filter_tahun    : new Date().getFullYear(),
            filter_bulana   : 1,
            filter_bulanb   : new Date().getMonth() + 1,
            filter_tgla     : 1,
            filter_tglb     : getLastDate(new Date().getFullYear(),new Date().getMonth() + 1)
        }
    })
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
    },[formFilterState.inputValues.pagenum])
    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue) => {
            dispatchFormFilterState({
                type: FORM_INPUT_UPDATE,
                value: inputValue,
                input: inputIdentifier
            });
        },
        [dispatchFormFilterState]
    );
    const handleFilterTipe = (e) => {
        let filter = e.target.value;
        if(filter==='tahunan'){
            setActionFilterTipe({
                harian : false,
                bulanan : false,
                tahunan : true,
            });
        }else if(filter==='bulanan'){
            setActionFilterTipe({
                harian : false,
                bulanan : true,
                tahunan : false,
            });
        }else if(filter==='harian'){
            setActionFilterTipe({
                harian : true,
                bulanan : false,
                tahunan : false,
            });
        }
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:filter,
            input:'filter_tipe'
        })
    }
    const handleFilterFunc = (e,id) => {
        let filter = e.target.value;
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:filter,
            input:id
        })
    }
    const handleGetLaporan = () => {
        getData();
    }
    // const handleRefresh = () => {
    //     dispatchFormFilterState({
    //         type:FORM_INPUT_RESET,
    //     });
    // }
    
    const handleMovePage = async(type,id) => {
        if(type==='prev'){
            if(formFilterState.inputValues.pagenum === 0){
                // setNotify(	{
                //     type:'danger',show:'block',title:'Data Faskes',message:'Sudah dihalaman satu'
                // });
                // setTimeout(() => {
                //     setNotify({
                //         ...notify
                //     });
                // }, 3000);
            }else{
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum-1,
                    input:id
                })
            }
        }else{
            if(((parseInt(formFilterState.inputValues.pagenum+1))*formFilterState.inputValues.pagesize) <= totalContent){
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum+1,
                    input:id
                })
                
            }else{
                // setNotify(	{
                //     type:'danger',show:'block',title:'Data Faskes',message:'Halaman sudah habis'
                // });
                // setTimeout(() => {
                //     setNotify({
                //         ...notify
                //     });
                // }, 3000);
            }
            
        }
    }
    const handleDetailTransaksi = (id) => {
        setStateTransaksiDetail(prevState => ({
            ...prevState,
            loadData : prevState.id!==id && prevState.id!=='' && prevState.loadData ? prevState.loadData : !prevState.loadData,
            id : id,
        }))
    }
    const checkCheck = (data) => {
        console.log(data);
        setShowModal(true);
        setDataDetail(data);
    }
    const getData = async() => {
        try{
            log('filterData',formFilterState.inputValues);
            setLoadData(true);
            POST('web/konsultasi/getLaporanKonsultasi',formFilterState.inputValues,authHeader())
            .then(({data}) => {
                let dataRaw = data.response.rows;
                let totalRaw = data.response.totalRows;
                let totalBelum = data.response.totalBelum;
                let nilaiBelum = formatMoney(parseFloat(data.response.nilaiBelum));
                let totalSudah = data.response.totalSudah;
                let nilaiSudah = formatMoney(parseFloat(data.response.nilaiSudah));
                setDataContent(dataRaw);
                setTotalContent(totalRaw);
                setLoadData(false);
                setNilaiFilter({
                    totalBelum : totalBelum,
                    nilaiBelum : nilaiBelum,
                    totalSudah : totalSudah,
                    nilaiSudah : nilaiSudah,
                });
            })
            .catch(error => {
                setLoadData(false);
                setDataContent([]);
                setNilaiFilter({
                    totalBelum : 0,
                    nilaiBelum : 0,
                    totalSudah : 0,
                    nilaiSudah : 0,
                });
                const messageErr =  handlingError.Response('page login - catch login',error,dispatch);
                setNotify(	{
                    type:'danger',show:'block',title:'Data Faskes',message:messageErr
                });
                setTimeout(() => {
                    setNotify({
                        ...notify
                    });
                }, 3000);
            });
        }catch(error){
            log('error',error);
        }
    }
  return (
    <div className="container mx-auto">
        <Notif 
                notify = {notify}
            />
        <TitlePage
            title='Laporan Konsultasi'
            data = {['Laporan','Konsultasi']}
        />
        <div className="antialiased font-sans bg-white">
            <div className="container mx-auto px-4 sm:px-4">
                <div className="py-4 relative">
                    <div className="py-0">Filter Detail : </div>
                    <div className="flex flex-row pb-3 pt-1 ">
                        <div className='w-1/5 pr-5'>
                             <SelectInput
                                id="filter_tipe"
                                data={filterTipe}
                                valueTipe='value'
                                onChange={(e) => handleFilterTipe(e)}
                            /> 
                        </div>
                        <div className='w-1/5 pr-5'>
                            <SelectInput 
                                id="filter_tahun"
                                data={filterTahun(new Date().getFullYear())}
                                value={formFilterState.inputValues.filter_tahun}
                                onChange={(e) => handleFilterFunc(e,'filter_tahun')}
                             /> 
                        </div>
                        <div className={'w-1/5 pr-5 '+ (actionFilterTipe.bulanan ? 'show' : 'hidden')}>
                            <SelectInput
                                id="filter_bulana"
                                data={filterBulan}
                                valueTipe='keyBulan'
                                value={formFilterState.inputValues.filter_bulana}
                                onChange={(e) => handleFilterFunc(e,'filter_bulana')}
                            /> 
                        </div>
                        <div className={'w-1/5 pr-5 '+(actionFilterTipe.tahunan ? 'hidden' : 'show')}>
                            <SelectInput
                                id="filter_bulanb"
                                data={filterBulan}
                                valueTipe='keyBulan'
                                value={formFilterState.inputValues.filter_bulanb}
                                onChange={(e) => handleFilterFunc(e,'filter_bulanb')}
                            />
                        </div>
                        <div className={"w-2/5 flex flex-row "+ (actionFilterTipe.harian ? 'show' : 'hidden')}>
                            <div className='w-1/2 pr-5'>
                                <SelectInput
                                    id="filter_tgla"
                                    data={filterTgl(formFilterState.inputValues.filter_tahun,formFilterState.inputValues.filter_bulana)}
                                    value={formFilterState.inputValues.filter_tgla}
                                    onChange={(e) => handleFilterFunc(e,'filter_tgla')}
                                />
                            </div>
                            <div className='pr-5 pt-1 text-center'>
                                s/d
                            </div>
                            <div className='w-1/2'>
                                <SelectInput
                                    id="filter_tglb"
                                    data={filterTgl(formFilterState.inputValues.filter_tahun,formFilterState.inputValues.filter_bulana)}
                                    value={formFilterState.inputValues.filter_tglb}
                                    onChange={(e) => handleFilterFunc(e,'filter_tglb')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <div>
                            <Button 
                                addclass="bg-green-500 text-white font-bold" 
                                onClick={() => handleGetLaporan()}
                            >
                                <FontAwesomeIcon icon={faFolderOpen}/> <span className='pl-2'>Tampilkan</span>
                            </Button>
                            {/* <Button 
                                addclass="bg-orange-500 text-white font-bold" 
                                onClick={() => handleRefresh()}
                            >
                                <FontAwesomeIcon icon={faSyncAlt}/> <span className='pl-2'>Refresh</span>
                            </Button> */}
                        </div>
                        <div className="my-2 flex sm:flex-row flex-col">
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput
                                    data={['Semua','Nama Pasien','Nama Dokter','Tanggal Konsultasi']} 
                                    // value={filterData.search_key}
                                    valueTipe = 'value'
                                    onChange={(e) => handleFilterFunc(e,'search_key')}
                                 />
                            </div>
                            <div className="block relative">
                                <InputIcon 
                                    icon='search' 
                                    id='search_value'
                                    onInputChange={inputChangeHandler}
                                    initialValue={formFilterState.inputValues.search_value}
                                />
                            </div>
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['10','20','50','100']}
                                    onChange={(e) => handleFilterFunc(e,'pagesize')}
                                    // onChange={(e) => handlePageSize(e)}
                                    // value={filterData.pagesize}
                                 />
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <ManualTable>
                                <ManualTHEAD>
                                    <ManualTR>
                                        <ManualTH>ID</ManualTH>
                                        <ManualTH>Fakses</ManualTH>
                                        <ManualTH>Nama Pasien</ManualTH>
                                        <ManualTH>Nama Dokter</ManualTH>
                                        <ManualTH>Transaksi Konsultasi</ManualTH>
                                        <ManualTH>Status</ManualTH>
                                    </ManualTR>
                                </ManualTHEAD>
                                <ManualTBODY>
                                    {
                                        (loadData) ? 
                                        <ManualTR>
                                            <ManualTD>
                                                <LoadingLittle />
                                            </ManualTD>
                                        </ManualTR>    
                                        : 
                                        dataContent.map((element) => {
                                            return (
                                                <TableBody  
                                                    data={element} 
                                                    key={element.id}
                                                    stateTransksi = {stateTransaksiDetail}
                                                    handleState = {(id) => handleDetailTransaksi(id)}
                                                    checkCheck = {(id) => checkCheck(id)}
                                                />
                                            )
                                        })
                                    }
                                </ManualTBODY>
                            </ManualTable>
                            <ManualPaging 
                              pagenum={formFilterState.inputValues.pagenum}  
                              pagesize={formFilterState.inputValues.pagesize}
                              totalContent = {totalContent}
                              handleMovePage={(e) => handleMovePage(e,'pagenum')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className=" py-4 flex-reserve">
                <div className="sm:grid sm:h-32 sm:grid-flow-row sm:gap-4 sm:grid-cols-2">
                                    
                    <div id="jh-stats-negative" className="flex flex-col justify-center px-4 py-4 mt-4 bg-white border border-gray-300 rounded sm:mt-0">
                        <div>
                            <p className="text-3xl font-semibold text-center text-gray-800">Rp. {nilaiFilter.nilaiBelum}</p>
                            <p className="text-lg text-center text-gray-500">{nilaiFilter.totalBelum} Belum Bayar</p>
                        </div>
                    </div>

                    <div id="jh-stats-neutral" className="flex flex-col justify-center px-4 py-4 mt-4 bg-white border border-gray-300 rounded sm:mt-0">
                        <div>
                                <p className="text-3xl font-semibold text-center text-gray-800">Rp. {nilaiFilter.nilaiSudah}</p>
                                <p className="text-lg text-center text-gray-500">{nilaiFilter.totalSudah} Pembayaran</p>
                        </div>
                    </div>
                </div>
            </div>
            {showModal ? (
        <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none h-72 max-h-4/5"
          onClick={() => setShowModal(false)}
        >
            <div className="relative my-6 mx-auto w-4/5 ">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                    <div className="flex items-start justify-between p-1 pl-5 border-b border-solid border-gray-300 rounded-t">
                        <h2 className="text-3xl font-semibold">
                        Modal Title
                        </h2>
                        <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                        >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto overflow-y-auto h-64 max-h-72">
                        <div className="flex-auto pr-5">
                            <table className="w-full min-w-full">
                                <tbody>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">ID</div>
                                            <div className="leading-5 text-gray-900">{dataDetail ? dataDetail.id : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Asuransi</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.asuransi ? dataDetail.asuransi+' ('+dataDetail.no_asuransi+')' : null}</div>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Faskes</div>
                                            <div className="leading-5 text-gray-900">{dataDetail ? dataDetail.faskes_code+' / '+dataDetail.nama_faskes : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Keluhan</div>
                                            <div className="leading-5 text-gray-900">{dataDetail ? dataDetail.keluhan : null}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Pasien</div>
                                            <div className="leading-5 text-gray-900">{dataDetail ? dataDetail.user_id+' / '+dataDetail.nama_pasien : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Lama Sakit</div>
                                            <div className="leading-5 text-gray-900">{dataDetail ? dataDetail.lama_sakit+' '+dataDetail.lama_sakit_tipe : null}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Metode Bayar</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.metode_bayar_id ? dataDetail.metode_bayar_id+' / '+dataDetail.nama_metode_bayar : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Tipe Transaksi</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.tipe ? dataDetail.tipe: null}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Finnet Page</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.finnet_landing_page ? dataDetail.finnet_landing_page : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Tarif</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.tarif ? dataDetail.tarif: null}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Finnet Status Code</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.finnet_status_code ? dataDetail.finnet_status_code : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Administrasi</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.tarif_adm ? dataDetail.tarif_adm : null}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Finnet Status Desc</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.finnet_status_desc ? dataDetail.finnet_status_desc : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Total Pembayaran</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.tarif_total ? dataDetail.tarif_total : null}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Kirim Email</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.is_kirimemail===1 ? 'Sudah Dikirm' : 'Belum Dikirim'}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Waktu Mulai</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.reg_time ? dataDetail.reg_time : null}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Vicall Session</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.vical_session ? dataDetail.vical_session : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Waktu Selesai</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.selesai_time ? dataDetail.selesai_time : null}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Vicall Token</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.vicall_token===1 ? dataDetail.vicall_token : null}</div>
                                        </td>
                                        <td className="px-2 py-2 whitespace-no-wrap">
                                            <div className="leading-5 text-gray-500 font-medium">Waktu Selesai</div>
                                            <div className="leading-5 text-gray-900">{dataDetail.selesai_time ? dataDetail.selesai_time : null}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b">
                        <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-1 text-sm outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => setShowModal(false)}
                        >
                        Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
      ) : null}
    </div>
  );
}
 
export default LaporanKonsultasi;