import React,{ useEffect,useState,useCallback,useReducer } from 'react';
import authHeader from '../../../../config/services/auth-header';
import { POST } from '../../../../config/api';
import {useDispatch, useSelector, useStore} from 'react-redux';

import * as handlingError from '../../../../config/redux/action/handling';
import Notif from '../../../../components/atoms/notif';
import {changeddmmyyyy,formatMoney} from '../../../../utils/formatDate'
import {filterTipe,filterTahun,filterBulan,filterTgl,getLastDate, dataHit, dataFilter} from '../../../../utils/filterData'

import TitlePage from '../../../../components/molecules/TitlePage';
import {ManualTable,ManualTHEAD,ManualTBODY,ManualTR,ManualTD,ManualTH,ManualPaging, ManualPaging2} from '../../../../components/molecules/DataTable';
import log from '../../../../utils/log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faSearch, faSortAmountDown, faSortAmountUp, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import SelectInput from '../../../../components/atoms/SelectInput';
import InputIcon from '../../../../components/atoms/InputIcon';
import Button from '../../../../components/atoms/button';
import LoadingLittle from '../../LoadingLittle'
import { getMaster } from '../../../../config/redux/action/filterMaster';
import SelectFilter from '../../../../components/atoms/selectFilter';

const TableBody = (props) => {
    return (
        <tr className="items-start">
            <ManualTD>
                <div className="flex items-start">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {props.data.id}
                    </p>
                </div>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.m_faskes_tipe_nama}</p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.nama}</p>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.faskes_code}</p>
            </ManualTD>
            <ManualTD>
                <p className="text-gray-900 whitespace-no-wrap">{props.data.hits}</p>
            </ManualTD>
            
        </tr>
          
    )
}

export const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
export const FORM_INPUT_RESET  = 'FORM_INPUT_RESET';

const formReducer = (state,action) => {
    if(action.type === FORM_INPUT_UPDATE){
        const updatedValues = {
            ...state.inputValues,
            [action.input] : action.value
        }
        return {
            inputValues : updatedValues,
        }
    }else if(action.type === FORM_INPUT_RESET){
        const updatedValues = {
            sortdatafield   : null,
            sortorder       : null,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
            filter_tipe     : 'harian',
            filter_tahun    : new Date().getFullYear(),
            filter_bulana   : 1,
            filter_bulanb   : new Date().getMonth() + 1,
            filter_tgla     : 1,
            filter_tglb     : getLastDate(new Date().getFullYear(),new Date().getMonth() + 1),
            member          : null,
            tipe            : null,
            hit             : null
        }
        return {
            inputValues : updatedValues,
        }
    }
    return state;
}
function LogFaskes() {
    const [dataContent,setDataContent]  = useState([]);
    const [totalContent,setTotalContent]  = useState(0);
    const [loadData,setLoadData]  = useState(false);
    const [notify,setNotify] 	= useState({show:'hidden'});
    const [showModal,setShowModal] 	= useState(false);
    const [dataDetail,setDataDetail] 	= useState([]);
    const [nilaiFilter,setNilaiFilter] 	= useState({
        totalBelum : 0,
        nilaiBelum : 0,
        totalSudah : 0,
        nilaiSudah : 0,
    });
    const [stateTransaksiDetail,setStateTransaksiDetail] 	= useState({
        loadData : false,
        id : '',
    });
    const [actionFilterTipe,setActionFilterTipe] = useState({
        harian : true,
        bulanan : false,
        tahunan : false,

    });  

    const [FilterMember, setFilterMember] = useState([])
    const [FilterTipe, setFilterTipe] = useState([])
    const [sort, setSort] = useState('asc')
    const [tipeSort, setTipeSort] = useState('nama')

    const dispatch = useDispatch();
    const store = useStore()
    const [formFilterState, dispatchFormFilterState] = useReducer(formReducer,{
        inputValues:{
            sortdatafield   : tipeSort,
            sortorder       : sort,
            pagenum         : 0,
            pagesize        : 10,
            search_value    : '',
            search_key      : 'semua',
            filter_tipe     : 'harian',
            filter_tahun    : new Date().getFullYear(),
            filter_bulana   : 1,
            filter_bulanb   : new Date().getMonth() + 1,
            filter_tgla     : 1,
            filter_tglb     : getLastDate(new Date().getFullYear(),new Date().getMonth() + 1),
            member          : null,
            tipe            : null,
            hit             : null
        }
    })

    useEffect(() => {
        // getData();
        dataInput()
        getDataMaster()
    },[formFilterState.inputValues.pagenum])
    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue) => {
            dispatchFormFilterState({
                type: FORM_INPUT_UPDATE,
                value: inputValue,
                input: inputIdentifier
            });
        },
        [dispatchFormFilterState]
    );
    const handleFilterTipe = (e) => {
        let filter = e.target.value;
        if(filter==='tahunan'){
            setActionFilterTipe({
                harian : false,
                bulanan : false,
                tahunan : true,
            });
        }else if(filter==='bulanan'){
            setActionFilterTipe({
                harian : false,
                bulanan : true,
                tahunan : false,
            });
        }else if(filter==='harian'){
            setActionFilterTipe({
                harian : true,
                bulanan : false,
                tahunan : false,
            });
        }
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:filter,
            input:'filter_tipe'
        })
    }
    const handleFilterFunc = (e,id) => {
        // console.log('cek',id)
        let filter = e.target.value;
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:filter,
            input:id
        })
        setTipeSort(filter)
    }

    const handlePagination = (e, id) => {
        // let filter = e.target.value;
        console.log(e)
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:Number(e),
            input:id
        })
    }
    
    const handleMovePage = async(type,id) => {
        if(type==='prev'){
            if(formFilterState.inputValues.pagenum === 0){
                // setNotify(	{
                //     type:'danger',show:'block',title:'Data Faskes',message:'Sudah dihalaman satu'
                // });
                // setTimeout(() => {
                //     setNotify({
                //         ...notify
                //     });
                // }, 3000);
            }else{
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum-1,
                    input:id
                })
            }
        }else{
            if(((parseInt(formFilterState.inputValues.pagenum+1))*formFilterState.inputValues.pagesize) <= totalContent){
                await dispatchFormFilterState({
                    type:FORM_INPUT_UPDATE,
                    value:formFilterState.inputValues.pagenum+1,
                    input:id
                })
                
            }else{
                // setNotify(	{
                //     type:'danger',show:'block',title:'Data Faskes',message:'Halaman sudah habis'
                // });
                // setTimeout(() => {
                //     setNotify({
                //         ...notify
                //     });
                // }, 3000);
            }
            
        }
    }
    const handleDetailTransaksi = (id) => {
        setStateTransaksiDetail(prevState => ({
            ...prevState,
            loadData : prevState.id!==id && prevState.id!=='' && prevState.loadData ? prevState.loadData : !prevState.loadData,
            id : id,
        }))
    }
    const checkCheck = (data) => {
        // console.log(data);
        setShowModal(true);
        setDataDetail(data);
    }

    const dataInput = (sortorder) => {
        const input = formFilterState.inputValues
        // return
        const data = {
            sortdatafield: input.sortdatafield,
            sortorder: sortorder !== undefined ? sortorder : input.sortorder,
            pagenum: input.pagenum,
            pagesize: input.pagesize,
            search_value: input.search_value,
            search_key: input.search_key,
            filter: {
                tahun: input.filter_tahun + '-' + input.filter_bulanb,
                hit: input.hit,
                tipe: input.tipe,
                member: input.member
            }
        }
        getData(data)
    }

    const getData = async(data) => {
        try{
            log('datanya', data)
            setLoadData(true);
            POST('web/faskes/getLogFaskes',data,authHeader())
            .then(({data}) => {
                // console.log('data', data)
                let dataRaw = data.response.rows;
                let totalRaw = data.response.totalRows;
                // console.log(data.response)
                setDataContent(dataRaw);
                setTotalContent(totalRaw);
                setLoadData(false);
            })
            .catch(error => {
                setLoadData(false);
                setDataContent([]);
                // const messageErr =  handlingError.Response('page login - catch login',error,dispatch);
                // setNotify(	{
                //     type:'danger',show:'block',title:'Data Faskes',message:messageErr
                // });
                // setTimeout(() => {
                //     setNotify({
                //         ...notify
                //     });
                // }, 3000);
            });
        }catch(error){
            log('error',error);
        }
    }

    const getDataMaster = async() => {
        await dispatch(getMaster())
        const  data = store.getState().filterMaster
        let status = []
        if(data.filter_member !== undefined && data.filter_member.length > 0){
            data.filter_member.map((index, i) => {
                const tipe = {
                    code: i,
                    nama: index
                }
                status.push(tipe)
            })
            setFilterMember(status)
    
            let Tipe = []
            data.filter_tipe.map((index, i) => {
                const tipe = {
                    code: index.code,
                    nama: index.nama
                }
                Tipe.push(tipe)
            })
            setFilterTipe(Tipe)
        } 
    }

    const handleSort = (data) => {
        dispatchFormFilterState({
            type:FORM_INPUT_UPDATE,
            value:data,
            input:'sortorder'
        })
        dataInput(data)
    }


  return (
    <div className="container mx-auto">
        <div className="antialiased font-sans bg-white">
            <Notif 
                    notify = {notify}
                />
            <TitlePage
                title='Laporan Log Faskes'
                data = {['Laporan','Log Faskes']}
            />
            <div className="container mx-auto px-4 sm:px-8">
                <div className="py-4 relative">
                    <div className="py-0">Filter Detail : </div>
                    <div className="flex flex-row pb-3 pt-1 ">
                        <div className='w-1/6 mr-5'>
                            <div className="py-0">Tahun : </div>
                            <SelectInput 
                                id="filter_tahun"
                                data={filterTahun(new Date().getFullYear())}
                                onInputChange={inputChangeHandler}
                                value={formFilterState.inputValues.filter_tahun}
                                onChange={(e) => handleFilterFunc(e,'filter_tahun')}
                             /> 
                        </div>
                        <div className={'w-1/6 mr-5 '+ (actionFilterTipe.bulanan ? 'show' : 'hidden')}>
                            <div className="py-0">Bulan : </div>
                            <SelectInput
                                id="filter_bulana"
                                data={filterBulan}
                                valueTipe='keyBulan'
                                value={formFilterState.inputValues.filter_bulana}
                                onChange={(e) => handleFilterFunc(e,'filter_bulana')}
                            /> 
                        </div>
                        <div className={'w-1/6 mr-5 '+(actionFilterTipe.tahunan ? 'hidden' : 'show')}>
                            <div className="py-0">Bulan : </div>
                            <SelectInput
                                id="filter_bulanb"
                                data={filterBulan}
                                valueTipe='keyBulan'
                                value={formFilterState.inputValues.filter_bulanb}
                                onChange={(e) => handleFilterFunc(e,'filter_bulanb')}
                            />
                        </div>
                        <div className={'w-1/6 mr-5 '}>
                            <div className="py-0">Pelanggan : </div>
                            <SelectFilter
                                id="filter_member"
                                data={FilterMember} 
                                value={formFilterState.inputValues.member}
                                valueTipe = 'value'
                                onChange={(e) => handleFilterFunc(e,'member')}
                            />
                        </div>
                        <div className={'w-1/6 mr-5 '}>
                            <div className="py-0">Tipe : </div>
                            <SelectFilter
                                id="filter_tipe"
                                data={FilterTipe} 
                                value={formFilterState.inputValues.tipe}
                                valueTipe = 'value'
                                onChange={(e) => handleFilterFunc(e,'tipe')}
                            />
                        </div>
                        <div className={'w-1/6 mr-5 '}>
                            <div className="py-0">Jumlah di Lihat : </div>
                            <SelectFilter
                                id="filter_hit"
                                data={dataHit} 
                                value={formFilterState.inputValues.hit}
                                valueTipe = 'value'
                                onChange={(e) => handleFilterFunc(e,'hit')}
                            />
                        </div>
                        <div className={'w-1/6 mr-5 '}>
                            <div className="py-0">Urutkan berdasarkan : </div>
                            <SelectFilter
                                id="sortdatafield"
                                data={dataFilter} 
                                value={formFilterState.inputValues.sortdatafield}
                                valueTipe = 'value'
                                onChange={(e) => {
                                    handleFilterFunc(e,'sortdatafield')
                                }}
                            />
                        </div>

                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <div className="my-2 flex sm:flex-row flex-col">
                            <div className="block relative">
                                <InputIcon 
                                    icon='search' 
                                    id='search_value'
                                    onInputChange={inputChangeHandler}
                                    initialValue={formFilterState.inputValues.search_value}
                                />
                            </div>
                            <div className="flex flex-row mb-1 sm:mb-0">
                                    <SelectInput 
                                        data={['10','20','50','100']}
                                        onChange={(e) => handleFilterFunc(e,'pagesize')}
                                    />
                                </div>
                            {/* <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['10','20','50','100']}
                                    onChange={(e) => handleFilterFunc(e,'pagesize')}
                                    // onChange={(e) => handlePageSize(e)}
                                    // value={filterData.pagesize}
                                 />
                            </div> */}
                        </div>
                            <div>
                                <Button 
                                    addclass="bg-green-500 text-white font-bold" 
                                    onClick={() => {
                                        dataInput()
                                        handlePagination('0', 'pagenum')
                                    }}
                                >
                                    <FontAwesomeIcon icon={faSearch}/> <span className='pl-2'>Terapkan</span>
                                </Button>
                            </div>
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <ManualTable>
                                <ManualTHEAD>
                                    <ManualTR>
                                        <ManualTH>ID</ManualTH>
                                        <ManualTH>Tipe</ManualTH>
                                        <ManualTH short={true} style={'flex mr-10 w-2/4 flex-row justify-between items-center'}>
                                            Faskes
                                            {tipeSort == 'nama' 
                                            ? 
                                            <Button
                                                onClick={async(e) => {
                                                    // console.log('cekcek', e)
                                                    if(sort == 'asc'){
                                                        setSort('desc')
                                                        handleSort('desc')
                                                    } else {
                                                        handleSort('asc')
                                                        setSort('asc')
                                                    }
                                                }}
                                            >
                                                {sort == 'desc' 
                                                ? 
                                                <FontAwesomeIcon icon={faSortDown}/>
                                                :
                                                <FontAwesomeIcon icon={faSortUp}/>
                                                }
                                            </Button>
                                            :
                                            null
                                            }
                                        </ManualTH>
                                        <ManualTH short={true} style={'flex mr-10 w-2/4 flex-row justify-between items-center'}>
                                            Jumlah Dilihat
                                            {tipeSort == 'hits' 
                                            ? 
                                            <Button
                                                onClick={async() => {
                                                    if(sort == 'asc'){
                                                        setSort('desc')
                                                        handleSort('desc')
                                                    } else {
                                                        handleSort('asc')
                                                        setSort('asc')
                                                    }
                                                }}
                                            >
                                                {sort == 'desc' 
                                                ? 
                                                <FontAwesomeIcon icon={faSortDown}/>
                                                :
                                                <FontAwesomeIcon icon={faSortUp}/>
                                                }
                                            </Button>
                                            :
                                            null
                                            }
                                        </ManualTH>
                                    </ManualTR>
                                </ManualTHEAD>
                                <ManualTBODY>
                                    {
                                        (loadData) ? 
                                        <ManualTR>
                                            <ManualTD>
                                                <LoadingLittle />
                                            </ManualTD>
                                        </ManualTR>    
                                        : 
                                        dataContent.map((element) => {
                                            return (
                                                <TableBody  
                                                    data={element} 
                                                    key={element.id}
                                                />
                                            )
                                        })
                                    }
                                </ManualTBODY>
                            </ManualTable>
                                <ManualPaging2
                                    pagenum={formFilterState.inputValues.pagenum}  
                                    pagesize={formFilterState.inputValues.pagesize}
                                    totalContent = {totalContent}
                                    handleMovePage={(e) => handleMovePage(e,'pagenum')}
                                    paginate={(e) => handlePagination(e, 'pagenum')}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default LogFaskes;