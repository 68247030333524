import React,{ useEffect,useState } from 'react';
import authHeader from '../../../../config/services/auth-header';
import { POST } from '../../../../config/api';

import * as handlingError from '../../../../config/redux/action/handling';
import Notif from '../../../../components/atoms/notif';

import TitlePage from '../../../../components/molecules/TitlePage';
import {ManualPaging,ManualTD,ManualTH} from '../../../../components/molecules/DataTable';
import log from '../../../../utils/log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import SelectInput from '../../../../components/atoms/SelectInput';
import Button from '../../../../components/atoms/button';
import LoadingLittle from '../../LoadingLittle'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'

const TableBody = (props) => {
    const data = props.data
    const history = useHistory();
    const handleClick = () => history.push('/metodepembayaran/'+data.id, { id: data.id });
    const tipefaskes = (data.tipe_faskes != null ? data.tipe_faskes.trim().replace(/\s/g, '').split(',') : [])

    return (
        
        <tr onDoubleClick={handleClick}>
            <ManualTD>
                <div className="flex items-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {data.id}
                    </p>
                </div>
            </ManualTD>
            <ManualTD>
                <div className="flex items-center">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                        <span className="inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        <p className="text-gray-900 whitespace-no-wrap px-1">{data.code}</p>
                        </span>
                    </div>
                    <div className="text-sm font-medium text-gray-900">
                        <p className="text-gray-900 whitespace-no-wrap">{data.value}</p>
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                        <p className="text-gray-500 whitespace-no-wrap">{data.description}</p>
                    </div>
                  </div>
                </div>
                
            </ManualTD>
            <ManualTD>
                {
                    data.status_android == '1' ? 
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Aktif
                    </span>:
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Tidak Aktif
                    </span>
                }
            </ManualTD>
            <ManualTD>
                {
                    data.status_ios == '1' ? 
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Aktif
                    </span>:
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Tidak Aktif
                    </span>
                }
            </ManualTD>
            <ManualTD>
                {
                    data.status_kunjungan == '1' ? 
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Aktif
                    </span>:
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Tidak Aktif
                    </span>
                }
            </ManualTD>
            <ManualTD>
                {
                    data.status_konsultasi == '1' ? 
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Aktif
                    </span>:
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Tidak Aktif
                    </span>
                }
            </ManualTD>
            <ManualTD>
                {
                    tipefaskes.length > 0 ?  
                    <ul>
                        {tipefaskes.map((item,i) => {
                            return <li key={i}>
                                <span className="px-2 mb-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    {item}
                                </span>
                            </li>
                        })}
                    </ul>
                    :
                    <ul>
                        <li>-</li>
                    </ul>
                }
            </ManualTD>
            <ManualTD>
                <div className="flex items-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {data.tarif_adm}
                    </p>
                </div>
            </ManualTD>
            <ManualTD>
                <div className="flex items-center">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {data.tarif_adm_kunjungan}
                    </p>
                </div>
            </ManualTD>
        </tr>
          
    )
}

const MasterPembayaran = props => {
    const [dataContent,setDataContent]  = useState([]);
    const [totalContent, setTotalContent] = useState(0);
    const [loadData,setLoadData]  = useState(false);
    const [filterData,setFilterData]    = useState({
        sortdatafield   : null,
        sortorder       : null,
        pagenum         : 0,
        pagesize        : 10,
        search_category : 'all',
        search_value    : null,
    });
    const [notify,setNotify] 	= useState({show:'hidden'});
    const dispatch = useDispatch()
    const getData = () => {
        try{
            setLoadData(true);
            POST('web/master/metodepembayaran/getData',filterData,authHeader())
            .then(({data}) => {
                console.log(data)
                let dataRaw = data.response.rows;
                let totalRaw = data.response.totalRows
                setTotalContent(totalRaw);
                setDataContent(dataRaw);
                setLoadData(false);
            })
            .catch(error => {
                setLoadData(false);
                const messageErr = handlingError.Response('page login - catch login',error, dispatch);
                setNotify(	{
                    type:'danger',show:'block',title:'Metode Pembayaran',message:messageErr
                });
                setTimeout(() => {
                    setNotify({
                        ...notify
                    });
                }, 3000);
            });
        }catch(error){
            log('error',error);
        }
    }
    useEffect(() => {
        getData();
    },[filterData])

    const handleRefresh = () => {
        getData();
    }

    const handleSearchKeyUp = (event) => {
        let value = event.target.value;
        if(value.length > 2){
            setFilterData(prevState => ({
                ...prevState,
                search_value: value,
            }))
            getData()
        } else if (value.length === 0){
            setFilterData(prevState => ({
                ...prevState,
                search_value: null,
            }))
            getData()
        }
    }

    const handleSelectChange = (event) => {
            let value = event.target.value;
            setFilterData(prevState => ({
                ...prevState,
                search_category    : value,
            }))
    }

    const handleSelectPageNum = (event) => {
        let value = event.target.value;
        setFilterData(prevState => ({
            ...prevState,
            pagesize    : value,
        }))
    }

    const handleMovePage = async(type,id) => {
        if(type === 'prev'){
            if(filterData.pagenum === 0){
                setNotify(	{
                    type:'danger',show:'block',title:'Metode Pembayaran',message:'Sudah dihalaman satu'
                });
                setTimeout(() => {
                    setNotify({
                        show: 'hidden'
                    });
                }, 1500);
            }else{
                setFilterData(prevState => ({
                    ...prevState,
                    pagenum    : prevState.pagenum-1,
                }))
            }
        }else{
            if(((parseInt(filterData.pagenum+1))*filterData.pagesize) <= totalContent){
                setFilterData(prevState => ({
                    ...prevState,
                    pagenum    : prevState.pagenum+1,
                }))
                
            }else{
                setNotify(	{
                    type:'danger',show:'block',title:'Metode Pembayaran',message:'Halaman sudah habis'
                });
                setTimeout(() => {
                    setNotify({
                        show: 'hidden'
                    });
                }, 1500);
            }
            
        }
    }
    
    return (
    <div className="container mx-auto">
        <Notif 
				notify = {notify}
			/>
        <TitlePage
            title='Master Pembayaran'
            data = {['Master','Pembayaran']}
        />
        <div className="antialiased font-sans bg-white">
            <div className="container mx-auto px-4 sm:px-8">
                <div className="py-8">
                    <div className="flex flex-row justify-between items-center">
                        <div>
                            <Button addclass="bg-green-500 text-white font-bold" 
                            onClick={() => handleRefresh()}
                            ><FontAwesomeIcon icon={faSyncAlt}/> <span className='pl-2'>Refresh</span></Button>
                        </div>
                        <div className="my-2 flex sm:flex-row flex-col">
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['Semua','Nama', 'Email', 'Ktp', 'STR', 'SIP']}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className="block relative">
                                <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                                    <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                                        <path
                                            d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                                        </path>
                                    </svg>
                                </span>
                                <input placeholder="Search"
                                    className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" 
                                    onKeyUp={handleSearchKeyUp} 
                                />
                            </div>
                            <div className="flex flex-row mb-1 sm:mb-0">
                                <SelectInput 
                                    data={['10','20','50','100']}
                                    onChange={handleSelectPageNum}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <ManualTH>ID</ManualTH>
                                        <ManualTH>Nama Pembayaran</ManualTH>
                                        <ManualTH>Status Android</ManualTH>
                                        <ManualTH>Status IOS</ManualTH>
                                        <ManualTH>Status Konsultasi</ManualTH>
                                        <ManualTH>Status Kunjungan</ManualTH>
                                        <ManualTH>Status Faskes</ManualTH>
                                        <ManualTH>Adm. Konsultasi</ManualTH>
                                        <ManualTH>Adm. Kunjungan</ManualTH>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (loadData) ? 
                                        <tr>
                                            <td>
                                                <LoadingLittle />
                                            </td>
                                        </tr>    
                                        : 
                                        dataContent.map((element) => {
                                            return (
                                                <TableBody  data={element} key={element.id} {...props}/>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <ManualPaging 
                              pagenum={filterData.pagenum}  
                              pagesize={filterData.pagesize}
                              totalContent = {totalContent}
                              handleMovePage={(e) => handleMovePage(e,'pagenum')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
 
export default MasterPembayaran;