import { GET, POST } from '../../api';
import authHeader from '../../services/auth-header'
import * as handlingError from './handling'

export const GET_MASTER = 'GET_MASTER'

export const getMaster = () => {
    return async dispatch => {
        dispatch({
            type: GET_MASTER,
            payload: {
                item: []
            }
        })

        const data = {
            table: ["member","faskesTipe"]
        }

        return POST('web/filter_master', data, authHeader())
        .then(({data}) => {
            const member = data.response.dt_member
            const faseks_type = data.response.dt_faskesTipe
            
            dispatch({
                type: GET_MASTER,
                payload: {
                    filter_member: member,
                    filter_tipe: faseks_type
                }
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}